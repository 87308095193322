import { method } from "@/api/api";

export const actions = {
  searchResidentByName({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.audit-report.search-company-name", params)
        .then(res => {
          resolve(res);
        }).catch(error => {
          reject(error);
        });
    });
  },
  getAuditReportsByCompanyTin({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.audit-report.get-by-company", params)
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getAdminAuditReports({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.audit-report.index", params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_AUDIT_REPORTS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  showAuditReportByUuid({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      method("crm.audit-report.get-by-uuid", { uuid })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  confirm({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("crm.audit-report.confirm", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  returnForIncomplete({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("crm.audit-report.return-for-incomplete", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  exportExcel({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.audit-report.excel-export", params)
        .then(res => {
          resolve(res);
        }).catch(error => {
          reject(error);
        })
    })
  },
  exportAsPdf({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.audit-report.pdf-export", params)
        .then(res => {
          resolve(res);
        }).catch(error => {
          reject(error);
        });
    });
  },
  exportAsPdfByAuditReportId({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      method("crm.audit-report.download-by-uuid", { uuid })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  returnForIncompleteByAccountant({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("crm.audit-report.return-for-incomplete-by-accountant", data)
        .then(res => {
          resolve(res);
        }).catch(error => {
          reject(error);
        });
    });
  },
  confirmByAccountant({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      method("crm.audit-report.confirm-by-accountant", { uuid })
        .then(res => {
          resolve(res);
        }).catch(error => {
          reject(error);
        });
    });
  },
};
