export const mutations = {
  SET_LIST: (state, data) => {
    state.list = data;
  },
  SET_LIST_ARCHIVE: (state, data) => {
    state.list_archive = data;
  },
  SET_KPI: (state, data) => {
    state.kpi = data;
    if(state.kpi.workers && state.kpi.workers.length){
      state.kpi.workers = state.kpi.workers.map(item => {
        item['all_count'] = 0;
        if(item['steps']){
          item['all_count'] = sumValues(item['steps']);
        }
        return item;
      })
    }
  },
};

function sumValues(obj) {
  // var sum = 0;
  // Object.values(obj).forEach(val => {
  //   sum += val;
  // })
  return Object.keys(obj).reduce((sum,key)=>sum+parseFloat(obj[key]||0),0);;
}