export default [
  // {
  //   path: "/soft-landing",
  //   name: "SoftLanding",
  //   meta: { middleware: "Auth" },
  //   component: () => import("@/views/softLanding/department.vue"),
  // },
  {
    path: "/soft-landing",
    name: "SoftLanding",
    meta: { middleware: "Auth" },
    component: () => import("@/views/softLanding/companies.vue"),
  },
  {
    path: "/soft-landing/appeal/:id",
    name: "SoftLandingDepartmentShow",
    meta: { middleware: "Auth" },
    component: () => import("@/views/softLanding/Show.vue"),
  },
  {
    path: "/soft-landing/crm/:id",
    name: "SoftLandingDepartmentCardShow",
    meta: { middleware: "Auth" },
    component: () => import("@/views/softLanding/CardShow.vue"),
  },
];
