export const getters = {
  QUARTER_REPORTS: (state) => state.quarterReports,
  ITPARK_REPORTS: (state) => state.itParkReports,
  EDUCATIONAL_COMPANIES: (state) => state.educationalCompanies,
  EDUCATIONAL_COMPANIES_PAGINATION: (state) =>
    state.educationalCompaniesPagination,
  AUDIT_REPORTS: (state) => state.auditReports,
  QUARTER_REPORTS_BY_COMPANIES: (state) => state.quarterReportsByCompanies,
  RESIDENT_INDICATORS: (state) => state.residentIndicators,
  MONTHLY_INCOMES_BY_COMPANIES: (state) => state.monthlyIncomesByCompanies,
  MONTHLY_INCOMES: (state) => state.monthlyIncomes,
  QUARTER_REPORTS_BY_COMPANY: (state) => state.quarterReportsByCompany,
  APPLICATION_PROCESSING_TIMES: (state) => state.applicationProcessingTimes,
};
