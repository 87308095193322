import { method } from "@/api/api";

export const actions = {
  getZeroRiskList({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.zero-risk.list", params)
          .then((res) => {
            if (res["success"] && res["data"]) {
              commit("SET_LIST", res["data"]);
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
    });
  },
  getSingle({ commit }, params) {
    return method("crm.zero-risk.show", params);
  },
  returnForIncomplete({ commit }, params) {
    return method("crm.zero-risk.return-for-incomplete", params);
  },
  confirm({ commit }, params) {
    return method("crm.local2global.confirm", params);
  },
  rejectFromReview({ commit }, params) {
    return method("crm.local2global.reject-from-review", params);
  },
  sendCommissionReview({ commit }, params) {
    return method("crm.zero-risk.send-commission-review", params);
  },
  commissionReview({ commit }, params) {
    return method("crm.zero-risk.commission-review", params);
  },

};
