import { method } from "@/api/api";

export const actions = {
  async getExpertOpinion({ commit }, params) {
    const res = await method("crm.export-opinion.index", params);
    commit("SET_EXPORT_OPINION", res.data.data);
    commit("SET_PAGE", res.data);
  },
  async getExpertOpinionsShow({ commit }, param) {
    const res = await method("crm.export-opinion.show", param);
    commit("SET_EXPORT_OPINION_SHOW", res.data);
  },
  async createExpertOpinion({ commit, dispatch }, data) {
    await method("crm.export-opinion.create", data);
    await dispatch("getExpertOpinion"); // Yangi ma'lumotni qayta yuklash
  },
  async getCompanies({ commit }) {
    const res = await method("crm.export-opinion.get-business-plan-application");
    commit("SET_COMPANIES", res.data);
  },
  async getExpertOpinionDocx({ commit }, params) {
    const res = await method("crm.export-opinion.get-detailed-data", params);
    commit("SET_EXPORT_OPINION_DOCX", res.data);
  },
  async addExpertOpinions({ commit }, selectedCompanies) {
    await method("crm.export-opinion.add-export-opinion-reestr", selectedCompanies);
  },
  async deleteExpertOpinion({ commit }, { id }) {
    // try {
    await method("crm.export-opinion.delete", { id }); // ✅ API chaqirilishi
    // commit("REMOVE_EXPERT_OPINION", id); // ✅ Vuex'dan o‘chirish
    // } catch (error) {
    //   console.error("Ошибка при удалении:", error);
    // }
  },
  async deleteBusinessPlanApplication({ commit }, params) {
    await method("crm.export-opinion.del-business-plan-application", params);
  }
};
