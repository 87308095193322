export const mutations = {
  SET_RESIDENTS: (state, items) => {
    state.residents = items.data;
    state.pagination = {
      total: items.total,
      page: items.current_page,
      limit: items.per_page,
      show: items.total > items.per_page,
      startIndex: (items["current_page"] - 1) * items["per_page"],
    };
  },
};
