// import { exportExcel, exportExcelListOfEInvoices, getInvoices, invoiceStatistics, searchResidentByName } from "@/api/invoices"

import { method } from "@/api/api";

export const actions = {
    getInvoices({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.invoices.index", params)
                .then(res => {
                    if (res['data']) {
                        commit('SET_INVOICES', res['data']);
                    }
                    resolve(res);
                }).catch(error => {
                    reject(error);
                });
        });
    },
    searchResidentByName({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.invoices.search-company", params)
                .then(res => {
                    resolve(res);
                }).catch(error => {
                    reject(error);
                });
        });
    },
    invoiceStatistics({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.invoices.get-by-company", params)
                .then(res => {
                    if (res['data']) {
                        commit('SET_INVOICE_STATISTICS', res);
                    }
                    resolve(res);
                }).catch(error => {
                    reject(error);
                });
        })
    },
    exportExcel({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.invoices.excel-export", params)
                .then(res => {
                    resolve(res);
                }).catch(error => {
                    reject(error);
                });
        });
    },
    exportExcelListOfEInvoices({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.invoices.get-by-company-excel", params)
                .then(res => {
                    resolve(res);
                }).catch(error => {
                    reject(error);
                });
        });
    }
}