export const mutations = {
    SET_LIST: (state, data) => {
        state.list = data['data'];
        state.pagination = {
            total: data['total'],
            last_page: data['last_page'],
            page: data['current_page'],
            limit: data['per_page'],
            startIndex: ((data['current_page'] - 1) * data['per_page']),
        }
    },
    SET_MISMATCHED_INVOICES: (state, data) => {
        state.mismatchedInvoices = data['data'];
        state.mismatchedInvoicesPagination = {
            total: data['total'],
            last_page: data['last_page'],
            page: data['current_page'],
            limit: data['per_page'],
            startIndex: ((data['current_page'] - 1) * data['per_page']),
        }
    },
    SET_COMPANY_INVOICES: (state, data) => {
        state.companyInvoices = data['data'];
        state.companyInvoicesPagination = {
            total: data['total'],
            last_page: data['last_page'],
            page: data['current_page'],
            limit: data['per_page'],
            startIndex: ((data['current_page'] - 1) * data['per_page']),
        }
    },
    SET_REVENUE: (state, data) => state.revenue = data
};
