const routes = [
  {
    path: "/zero-risk",
    name: "ZeroRisk",
    meta: { middleware: "Auth" },
    component: () => import("@/views/zero-risk/Index.vue"),
  },
  {
    path: "/zero-risk/:uuid",
    name: "ZeroRiskShow",
    meta: { middleware: "Auth" },
    component: () => import("@/views/zero-risk/Show.vue"),
  },
];

export default routes;
