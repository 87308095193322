import {orderBy, getPercentage} from '@/utils/index'

export const mutations = {
  SET_POSITIONS_NEW: (state, data) => {
    state.positions_new = data.map(item => {
      item['indicators'] = JSON.parse(item['indicators']).map(indicator => {
        indicator['opened'] = false;
        if(indicator['yearly']['total_count']){
          indicator['yearly']['count'] = indicator['yearly']['total_count'];
        }
        if(indicator['yearly']['total_ball']){
          indicator['yearly']['ball'] = indicator['yearly']['total_ball'];
        }
        if(indicator['monthly']['total_count']){
          indicator['monthly']['count'] = indicator['monthly']['total_count'];
        }
        if(indicator['monthly']['total_ball']){
          indicator['monthly']['ball'] = indicator['monthly']['total_ball'];
        }
        if(indicator['dekada']['1_total']){
          indicator['dekada']['1'] = indicator['dekada']['1_total'];
        }
        if(indicator['dekada']['2_total']){
          indicator['dekada']['2'] = indicator['dekada']['2_total'];
        }
        if(indicator['dekada']['3_total']){
          indicator['dekada']['3'] = indicator['dekada']['3_total'];
        }
        return indicator;
      });
      var total_balls = getTotalBall(item['indicators']);
      item['total_balls_yearly'] = total_balls['yearly'];
      item['total_balls_monthly'] = total_balls['monthly'];
      return item;
    });
  },
  SET_POSITIONS_SORT: (state, params) => {
    var positions = orderBy(state.positions_new.filter(item => item.row_type == 'position'), `total_balls_${params['type_table']}`, params['sort']);
    var structures = state.positions_new.filter(item => item.row_type != 'position');
    var arr = [];
    structures.forEach((structure) => {
      arr.push(structure);
      if(structure.row_type == 'structure'){
        positions.filter(position => position.structure_id == structure.structure_id).forEach(position => {
          arr.push(position);
        });
      }
    });
    state.positions_new = arr;
  },
  SET_POSITIONS: (state, data) => {
    state.positions = data.map(item => {
      item['indicators'] = item['indicators'].map(indicator => {
        indicator['opened'] = false;
        return indicator;
      });
      item['total_ball'] = calcTotalBall(item['indicators']);
      if(item['structure_head']){
        item['structure_head']['total_ball'] = calcTotalBall(item['structure_head']['indicators']);
      }
      var headPosition = item['positions'].find(position => position.position_type == 1);
      if(headPosition && headPosition.position_kpi_type != "single"){
        headPosition['indicators'] = item['indicators'];
        headPosition['total_ball'] = item['total_ball'];
      }
      return item;
    });
  },
  SET_STEPS: (state, data) => {
    state.steps = data;
  },
  SET_POSITIONS_EXPORT: (state, inputs) => {
    const data = inputs['data'];
    const params = inputs['params'];
    data.forEach(exportitem => {
      state.positions.forEach(structure => {
        var position = structure.positions.find(position => position.position_id == exportitem.position_id);
        if(position){
          if(position.position_type != 1 || position.row_type == 'structure_head'){
            addExportData(position.indicators, exportitem, params);
          }
          addExportData(structure.indicators, exportitem, params);
          if(structure.structure_head){
            addExportData(structure.structure_head.indicators, exportitem, params);
          }
          if(structure.structure_parent_id){
            var parentStructure = state.positions.find(position => position.structure_id == structure.structure_parent_id);
            if(parentStructure){
              addExportData(parentStructure.indicators, exportitem, params);
              if(parentStructure.structure_head){
                addExportData(parentStructure.structure_head.indicators, exportitem, params);
              }
            }
          }
        }
      });
    });
  },
  SET_STRUCTURES: (state, data) => {
    state.structures = data;
  },
  SET_KPI_POSITIONS: (state, data) => {
    state.kpiPositions = data.map(structure => {
      structure['indicators'] = structure['indicators'].map(indicator => {
        return mutationIndicators(indicator);
      })
      if(structure['positions']){
        structure['positions'].map(position => {
          if(position.position_type == 1){
            position['indicators'] = structure['indicators'];  
          }else{
            position['indicators'].map(indicator => {
              return mutationIndicators(indicator);
            });
          }
          position['total_ball'] = position['indicators'].reduce((partialSum, a) => partialSum + a.ball , 0);
          return position;
        })
      }
      structure['total_ball'] = structure['indicators'].reduce((partialSum, a) => partialSum + a.ball , 0);
      return structure;
    });
  },
};

function mutationIndicators(indicator) {
  indicator['opened'] = false;
  indicator['ball'] = calcBall(indicator['count'], indicator['plan'], indicator['indicator_ball']);
  indicator['diff'] = getDiff(indicator['count'], indicator['plan']);
  indicator['percentage'] = getPercentage(indicator['plan'], indicator['count']);
  return indicator;
}

function getDiff(count, plan) {
  if(count && plan){
    return plan-count;
  }
  return 0;
}

function addExportData(indicators, exportitem, params){
  indicators.forEach(indicator => {
    if(indicator.indicator_type == 'export'){
      if(params.type == 'dekada'){
        indicator[`dekada_${exportitem.date}`] = Number(indicator[`dekada_${exportitem.date}`])+Number(exportitem.export_amount);
      }else{
        indicator['count'] = Number(indicator['count'])+Number(exportitem.export_amount);
        indicator['ball'] = calcBall(indicator['count'],indicator['plan'],indicator['max_ball']);
      }
    }
  })
}

function calcHeadPosition(indicator, position, data){
  var headPosition = data.find(item => item.structure_id == position.structure_id && item.position_type == 1);
  if(headPosition){
    mergeIndicators(headPosition, indicator);
  }
  var structure = data.find(item => item.structure_id == position.structure_id && item.row_type == 'structure');
  if(structure){
    mergeIndicators(structure, indicator);
    if(structure.structure_parent_id){
      var parentStructure = data.find(item => item.structure_id == structure.structure_parent_id && item.row_type == 'structure');
      if(parentStructure){
        mergeIndicators(parentStructure, indicator);
        var headPositions = data.filter(item => item.structure_id == parentStructure.structure_id && item.position_type == 1);
        if(headPositions.length){
          headPositions.forEach(positionItem => {
            mergeIndicators(positionItem, indicator);
          });
        }
      }
    }
  }
}

function mergeIndicators(position, indicator){
  var pIndicator = position.indicators.find(item => item.indicator_id == indicator.indicator_id);
  if(pIndicator){
    pIndicator['dekada']['1'] += indicator['dekada']['1'];
    pIndicator['dekada']['2'] += indicator['dekada']['2'];
    pIndicator['dekada']['3'] += indicator['dekada']['3'];
    if(indicator['yearly']['count']){
      pIndicator['yearly']['count'] = Number(pIndicator['yearly']['count'])+Number(indicator['yearly']['count']);
      pIndicator['yearly']['ball'] = calcBall(pIndicator['yearly']['count'], pIndicator['yearly']['plan'], indicator['max_ball']);
    }
    if(indicator['monthly']['count']){
      pIndicator['monthly']['count'] = Number(pIndicator['monthly']['count'])+Number(indicator['monthly']['count']);
      pIndicator['monthly']['ball'] = calcBall(pIndicator['monthly']['count'], pIndicator['monthly']['plan'], indicator['max_ball']);
    }
  }
}

function calcBall(count, plan, max_ball){
  if(plan){
    var ball = Number(count)/Number(plan)*Number(max_ball);  
    return ball > max_ball?max_ball:ball;
  }
  return 0;
}

function getTotalBall(indicators){
  var total = {
    'yearly': 0,
    'monthly': 0,
  };
  indicators.forEach(item => {
    total['yearly'] += Number(item['yearly']['ball'])
    total['monthly'] += Number(item['monthly']['ball'])
  })
  return total;
}

function calcTotalBall(indicators){
  var total = 0;
  indicators.forEach(item => {
    total += Number(item['ball'])
  })
  return total;
}