import request from '@/utils/request';

function recursiveCheckObj(parentKey, value, formData) {
    if (typeof value == 'object') {
        if (Array.isArray(value)) {
            value.forEach((child, index) => {
                recursiveCheckObj(`${parentKey}[${index}]`, child, formData);
            });
        } else {
            Object.keys(value || {}).forEach(key => {
                recursiveCheckObj(parentKey + `[${key}]`, value[key], formData);
            })
        }
    } else if(typeof value == 'boolean') {
        formData.append(parentKey, Number(value));
    } else {
        formData.append(parentKey, value);
    }
}

export function storeOSS(data) {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
        if (key != 'passport') {
            recursiveCheckObj(key, data[key], formData);
        } else {
            formData.append(key, data[key]);
        }
    });

    return request({
        url: '/legal-help-request',
        method: 'post',
        data: formData
    });
}

export function getOSSes(params) {
    return request({
        url: '/legal-help-request',
        method: 'get',
        params
    });
}

export function getOSS(id) {
    return request({
        url: `/legal-help-request/${id}`,
        method: 'get'
    })
}

export function deleteOSS(id) {
    return request({
        url: `/legal-help-request/${id}`,
        method: 'DELETE'
    });
}