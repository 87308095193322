import { method } from "@/api/api";

export const actions = {
    getRequests({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.resident-requests.index", params).then(res => {
                commit('SET_REQUESTS', res['data']);
                resolve(res);
            }).catch(error => {
                reject(error);
            });
        });
    },

    getRequest({ commit }, id) {
        return method("crm.resident-requests.show", { id })
    },

    deleteRequest({ commit }, id) {
        return method("crm.resident-requests.delete", { id })
    }
}