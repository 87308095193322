const routes = [
  {
    path: '/marketingDepartment',
    name: 'marketingDepartment',
    meta: { middleware: 'Auth' },
    redirect: {name: 'marketingDepartmentCompanies'},
    component: () =>
    import("@/views/marketingDepartment/index"),
  },{
    path: '/marketingDepartment/companies',
    name: 'marketingDepartmentCompanies',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/marketingDepartment/companies.vue"),
  },{
    path: '/marketingDepartment/leads',
    name: 'marketingDepartmentLeads',
    meta: { middleware: 'Auth' },
    component: () =>
        import("@/views/marketingDepartment/leads.vue"),
  },{
    path: '/marketingDepartment/tasks',
    name: 'marketingDepartmentTasksMain',
    redirect: {name: 'marketingDepartmentTasksWorkspaces'},
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/marketingDepartment/tasks/tasks"),
  },{
    path: '/marketingDepartment/tasks/workspaces',
    name: 'marketingDepartmentTasksWorkspaces',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/marketingDepartment/tasks/workspaces"),
  },{
    path: '/marketingDepartment/tasks/board/:id',
    name: 'marketingDepartmentTasks',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/marketingDepartment/tasks/tasks"),
  },{
    path: '/marketingDepartment/cards/sources',
    name: 'marketingDepartmentCardSources',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/marketingDepartment/cardSources"),
  }
]

export default routes;
