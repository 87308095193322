import request from "@/utils/request";
import { method } from "@/api/api";

export const actions = {
    profileUpdate({ commit }, data) {
        return profileUpdate(data);
    },
    getNotification({ commit }) {
        return new Promise((resolve, reject) => {
            getNotification()
                .then((res) => {
                    if (res["success"] && res["data"]) {
                        commit("SET_NOTIFICATION", res["data"]);
                    }
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    sendRatings({ commit }, data) {
        return sendRatings(data);
    },
    residents({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.active-residents.list", params)
                .then((res) => {
                    console.log(res);

                    if (res["success"] && res["data"]) {
                        commit("SET_RESIDENTS", res["data"]);
                    }
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    residentUpdateRequest({ commit }, data) {
        return method("crm.active-residents.edit", data);
    },
    showCompany({ commit }, id) {
        return new Promise((resolve, reject) => {
            method("crm.active-residents.getbytin", { tin: id })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getActualBp({ commit }) {
        return new Promise((resolve, reject) => {
            getActualBp()
                .then((res) => {
                    if (res["success"] && res["data"]) {
                        commit("SET_ACTUAL_BP", res["data"]);
                    }
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    signOffer({ commit }, data) {
        return signOffer(data);
    },
    // For registration role
    getRegistrationResidents({ commit }, params) {
        return new Promise((resolve, reject) => {
            getRegistrationResidents(params)
                .then((res) => {
                    if (res["success"] && res["data"]) {
                        commit("SET_RESIDENTS", res["data"]);
                    }
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getCities({ commit }, params) {
        return new Promise((resolve, reject) => {

            getCities(params)
                .then(res => {
                    if (res['success'] && res['data']) {
                        commit('SET_CITIES', res['data']);
                    }
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    showRegistrationCompany({ commit }, id) {
        return new Promise((resolve, reject) => {
            showRegistrationCompany(id)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    // For monitoring role
    getMonitoringResidents({ commit }, params) {
        return new Promise((resolve, reject) => {
            getMonitoringResidents(params)
                .then((res) => {
                    if (res["success"] && res["data"]) {
                        commit("SET_RESIDENTS", res["data"]);
                    }
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    showMonitoringCompany({ commit }, id) {
        return new Promise((resolve, reject) => {
            showMonitoringCompany(id)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    // For support role
    getSupportResidents({ commit }, params) {
        return new Promise((resolve, reject) => {
            getSupportResidents(params)
                .then((res) => {
                    if (res["success"] && res["data"]) {
                        commit("SET_RESIDENTS", res["data"]);
                    }
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    showSupportCompany({ commit }, id) {
        return new Promise((resolve, reject) => {
            showSupportCompany(id)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    residentVoices({ commit }, { companyTin, params }) {
        return new Promise((resolve, reject) => {
            method("crm.invoices.get-by-company", { tin: companyTin, ...params })
                .then((res) => {
                    if (res["success"] && res["data"]) {
                        commit("SET_RESIDENT_VOICES", res["data"]);
                    }
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    residentVoiceByGnkId({ commit }, gnkId) {
        return new Promise((resolve, reject) => {
            method("crm.invoices.get-by-gnk-id", { gnk_id: gnkId })
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    togglePreviousRouteName({ commit }, routeName) {
        return new Promise((resolve, reject) => {
            try {
                commit("SET_FROM_ROUTE_NAME", routeName);
                resolve(true);
            } catch (error) {
                reject(error);
            }
        });
    },

    exportExcel({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.active-residents.excel-export", params)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getVirtualOfficeApplications({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.virtual-office-applications.index", params)
                .then((res) => {
                    commit("SET_VIRTUAL_OFFICE", res["data"]);
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getVirtualOfficeApplication({ commit }, id) {
        return new Promise((resolve, reject) => {
            method("crm.virtual-office-applications.show", id)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    storeVirtualOfficeApplication({ commit }, data) {
        return new Promise((resolve, reject) => {
            storeVirtualOfficeApplication(data)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    checkVirtualOfficeApplication({ commit }) {
        return new Promise((resolve, reject) => {
            checkVirtualOfficeApplication()
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    sendVirtualOfficeReview({ commit }, id) {
        return new Promise((resolve, reject) => {
            sendVirtualOfficeReview(id)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateVirtualOfficeApplication({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateVirtualOfficeApplication(data)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    returnVirtualOfficeForIncomplete({ commit }, data) {
        return new Promise((resolve, reject) => {
            method("crm.virtual-office-applications.return-for-incomplete", data)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    returnVirtualOfficeForInProcessing({ commit }, data) {
        return new Promise((resolve, reject) => {
            method("crm.virtual-office-applications.in-process", data)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    confirmVirtualOffice({ commit }, id) {
        return new Promise((resolve, reject) => {
            method("crm.virtual-office-applications.confirm", id)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getVirtualOfficeContracts({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.virtual-office-contract.index", params)
                .then((res) => {
                    commit("SET_VIRTUAL_OFFICE_CONTRACT", res["data"]);
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    searchVirtualOfficeApplicationByNumber({ commit }, id) {
        return new Promise((resolve, reject) => {
            method("crm.virtual-office-applications.search-by-number", { id })
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    storeVirtualOfficeContract({ commit }, data) {
        return new Promise((resolve, reject) => {
            method("crm.virtual-office-contract.store", data)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getVirtualOfficeContract({ commit }, id) {
        return new Promise((resolve, reject) => {
            method("crm.virtual-office-contract.show", { id })
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateVirtualOfficeContract({ commit }, data) {
        return new Promise((resolve, reject) => {
            method("crm.virtual-office-contract.edit", data)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    sendVirtualOfficeContractReview({ commit }, data) {
        return new Promise((resolve, reject) => {
            method("crm.virtual-office-contract.send-review", data)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    confirmVirtualOfficeContract({ commit }, data) {
        return new Promise((resolve, reject) => {
            confirmVirtualOfficeContract(data)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    returnVirtualOfficeContractForIncomplete({ commit }, data) {
        return new Promise((resolve, reject) => {
            returnVirtualOfficeContractForIncomplete(data)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    virtualOfficeContractPaymentReceipt({ commit }, data) {
        return new Promise((resolve, reject) => {
            virtualOfficeContractPaymentReceipt(data)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    returnVirtaulOfficeContractForIncompleteByAdmin({ commit }, data) {
        return new Promise((resolve, reject) => {
            method("crm.virtual-office-contract.return-for-incomplete-by-admin", data)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    confirmVirtualOfficeContractByAdmin({ commit }, data) {
        method("crm.virtual-office-contract.confirm-by-admin", data)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    },
    residentHistory({ commit }, tin) {
        console.log(tin)
        return new Promise((resolve, reject) => {
            method("crm.active-residents.resident-confirmation-history", { tin })
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    expulsionResidentHistory({ commit }, tin) {
        return new Promise((resolve, reject) => {
            method("crm.active-residents.resident-expulsion-history", { tin })
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    searchResidentByName({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.active-residents.search-by-name", params)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    storeCourseStudent({ commit }, data) {
        return new Promise((resolve, reject) => {
            storeCourseStudent(data)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getCourseStudents({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.course-student.index", params)
                .then((res) => {
                    commit("SET_COURSE_STUDENTS", res["data"]);
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getCourseStudent({ commit }, uuid) {
        return method("crm.course-student.show", { uuid })
    },

    sendCourseStudentReview({ commit }, data) {
        return new Promise((resolve, reject) => {
            sendCourseStudentReview(data)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    returnCourseStudentForIncomplete({ commit }, data) {
        return new Promise((resolve, reject) => {
            method("crm.course-student.return-for-incomplete", data)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    confirmCourseStudentByAdmin({ commit }, data) {
        return new Promise((resolve, reject) => {
            method("crm.course-student.confirm", data)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    updateCourseStudent({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateCourseStudent(data)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    searchResidentByNameForCourseStudent({ commit }, params) {
        return method("crm.course-student.search-company-name", params)
    },
    cancelVirtualOfficeContractByAdmin({ commit }, data) {
        return new Promise((resolve, reject) => {
            method("crm.virtual-office-contract.cancel-by-admin", data)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getCompanyTickets({ commit }, params) {
        return new Promise((resolve, reject) => {
            getCompanyTickets(params)
                .then((res) => {
                    commit("SET_COMPANY_TICKETS", res["data"]);
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getZeroRiskApplications({ commit }, params) {
        return new Promise((resolve, reject) => {
            return request({
                url: "/zero-risk",
                method: "GET",
                params,
            })
                .then((res) => {
                    commit("SET_ZERO_RISK", res["data"]);
                    resolve(res);
                })
                .catch((er) => reject(er))
        })
    },
    // postZeroRiskApplication({ commit }, data) {
    //     return new Promise((resolve, reject) => {
    //         const formData = new FormData();
    //         Object.keys(data).forEach((key) => {
    //             if (key === "founder_turnover_verification" || key === "residency_certificate") {
    //                 formData.append(key, data[key]);
    //             } else if (key === "eligibility_documents") {
    //                 data.eligibility_documents.forEach((doc, docIndex) => {
    //                     if (doc) {
    //                         Object.keys(doc).forEach((key, value) => {
    //                             if (key !== 'label') {
    //                                 formData.append(`eligibility_documents[${docIndex}][${key}]`, doc[key]);
    //                             }
    //                         });
    //                     }
    //                 });

    //             } else {
    //                 recursiveCheckObj(key, data[key], formData);
    //             }
    //         });
    //         return request({
    //             url: "/zero-risk",
    //             method: "POST",
    //             data: formData,
    //         })
    //             .then((res) => {
    //                 resolve(res);
    //             })
    //             .catch((er) => reject(er))
    //     })
    // },

    updateZeroRiskApplication({ commit }, data) {
        var newData = {};
        Object.keys(data).forEach(key => {
            if (data[key]) {
                newData[key] = data[key];
            }
        })
        return new Promise((resolve, reject) => {
            return request({
                url: `/zero-risk/${data.id}`,
                method: "PUT",
                data: newData,
            })
                .then((res) => {
                    resolve(res);
                })
                .catch((er) => reject(er))
        })
    },
    getZeroRiskApplication({ commit }, id) {
        return new Promise((resolve, reject) => {
            return request({
                url: `zero-risk/${id}`,
                method: "GET",
            }).then(res => {
                resolve(res)
            }).catch(err => reject(err))
        })
    },
    sendReviewZeroRisk({ commit }, id) {
        return new Promise((resolve, reject) => {
            return request({
                url: `zero-risk/send-review/${id}`,
                method: "PUT",
            }).then(res => {
                resolve(res)
            }).catch(err => reject(err))
        })
    },
    expelledResidentsExcel({ commit }, params) {
        return new Promise((resolve, reject) => {
            method('crm.residents.expelled-residents-excel', params)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    expelledResidents({ commit }, params) {
        return new Promise((resolve, reject) => {
            method('crm.residents.expelled-residents', params)
                .then((res) => {
                    if (res["success"] && res["data"]) {
                        commit("SET_EXPELLED_RESIDENTS", res["data"]);
                    }
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
