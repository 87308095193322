export const getters = {
  COUNTRIES: (state) => state.countries,
  REGIONS: (state) => state.regions,
  CITIES: state => state.cities,
  BANKS: state => state.banks,
  POSITIONS: (state) => state.positions,
  FORM_OPTIONS: (state) => state.formOptions,
  INN_LIST: (state) => state.innList,
  FILIALS: state => state.filials,
  COMPANY_STATUSES: (state) => state.companyStatuses,
}
