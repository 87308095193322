import { method } from "@/api/api";

export const actions = {
  getQuarterlyReports({ commit }, params) {
    return new Promise((resolve, reject) => {
      quarterlyReports()
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_QUARTERLY_REPORTS", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCourses({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("dsjdksjdskdsds", params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  showQuarterlyReportByUuid({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      method("crm.quarterly-reports.get-by-uuid", { uuid })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  confirm({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("crm.quarterly-reports.confirm", data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  returnForIncomplete({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("crm.quarterly-reports.return-for-incomplete", data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  confirmByAccountant({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      method("crm.quarterly-reports.confirm-by-accountant", { uuid })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  returnForIncompleteByAccountant({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("crm.quarterly-reports.return-for-incomplete-by-accountant", data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  adminUpdateQuarterlyReport({ commit }, data) {
    return method("crm.quarterly-reports.update", data);
  },
  searchResidentByName({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.quarterly-reports.search-company-name", params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getFullAmount({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.quarterly-reports.quarterly-reports-old-price-info", params)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_FULL_AMOUNT", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getStatusHistory({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      method("crm.quarterly-reports.get-status-history", uuid)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getQuarterlyReportsByCompanyTin({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.quarterly-reports.get-by-company", params)
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getAdminQuarterlyReports({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.quarterly-reports.index", params)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_QUARTERLY_REPORTS", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  exportAsPdf({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.quarterly-reports.export-pdf", params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  exportExcel({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.quarterly-reports.excel-export", params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadByUuid({ commit }, uuid) {
    return method("crm.quarterly-reports.download-by-uuid", { uuid });
  },
};
