export const state = {
  quarterReports: [],
  educationalCompanies: [],
  itParkReports: [],
  educationalCompaniesPagination: [],
  auditReports: {
    items: [],
    pagination: null,
  },
  quarterReportsByCompanies: {
    items: [],
    pagination: null,
  },
  applicationProcessingTimes: {
    items: [],
    pagination: null,
  },
  quarterReportsByCompany: {
    items: [],
    pagination: null,
  },
  residentIndicators: {
    items: [],
    pagination: null,
  },
  monthlyIncomesByCompanies: {
    summa_amount: 0,
    summa_export: 0,
    items: [],
    pagination: null,
  },
  monthlyIncomes: null,
};
