import { mapGetters, mapActions } from "vuex";
import {
  cloneObject as utilsCloneObject,
  orderBy as utilsOrderBy,
} from "@/utils/index";

export default {
  data() {
    return {
      screenWidth: 1400,
      statusTags: [
        {
          name: "Черновик",
          type: "info",
          id: 0,
        },
        {
          name: "На расмотрении",
          type: "default",
          id: 1,
        },
        {
          name: "Передано в Экспертный совет",
          type: "default",
          id: 2,
        },
        {
          name: "Отказано в рассмотрении",
          type: "danger",
          id: 3,
        },
        {
          name: "Возвращено на доработку",
          type: "warning",
          id: 4,
        },
        {
          name: "Подтвержден",
          type: "success",
          id: 5,
        },
        {
          name: "Передано руководителю экспертной группы",
          type: "default",
          id: 7,
        },
        {
          name: "Рассмотрено",
          type: "default",
          id: 8,
        },
      ],
      zeroRiskStatusTags: [
        {
          name: "Черновик",
          type: "info",
          id: 0,
        },
        {
          name: "Дирекция рассматривает",
          type: "default",
          id: 1,
        },
        {
          name: "Подтвержден",
          type: "success",
          id: 2,
        },
        {
          name: "Передано в Экспертный совет",
          type: "default",
          id: 3,
        },
        {
          name: "Возвращено на доработку",
          type: "warning",
          id: 4,
        },
        {
          name: "Комиссия подтвердила",
          type: "success",
          id: 7,
        },
        {
          name: "Комиссия отказалась",
          type: "danger",
          id: 8,
        },
      ],
      furniture: [
        {
          label: "Предоставил IT Park",
          value: 'itpark',
        },
        {
          label: "Предоставил хокимият",
          value: 'government',
        },
        {
          label: "Нету мебели",
          value: 'no_furniture',
        },
      ],
      equipment: [
        {
          label: "Предоставил IT Park",
          value: 'itpark',
        },
        {
          label: "Предоставил хокимият",
          value: 'government',
        },
        {
          label: "Нету оборудования",
          value: 'no_equipment',
        },
      ],
      location: [
        {
          label: "Центр города",
          value: 'city_center',
        },
        {
          label: "Окраина города",
          value: 'city_outskirt',
        },
        {
          label: "Находится в районе",
          value: 'located_in_region',
        },
      ],
      languages: [
        {
          label: "Английский",
          value: "en",
        },
        {
          label: "Русский",
          value: "ru",
        },
        {
          label: "Немецкий",
          value: "de",
        },
        {
          label: "Испанский",
          value: "es",
        },
        {
          label: "Французский",
          value: "fr",
        },
        {
          label: "Арабский",
          value: "ar",
        },
        {
          label: "Корейский",
          value: "ko",
        },
        {
          label: "Японский",
          value: "ja",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isAuth: "auth/IS_AUTH",
      currentUser: "auth/USER",
      loadRunningGetter: "loader/REQUEST_RUNNING",
      hackathonStartDate: "applications/START_DATE",
      prevRoute: "app/PREV_ROUTE",
      endVote: "votation/END_VOTE",
      regions: "app/REGIONS",
      formOptions: "resource/FORM_OPTIONS",
    }),
    hasPermissionItPark() {
      return (
        this.selectedUserIds ||
        this.$_.get(this.currentUser, "role", false) === "commission" ||
        this.$_.get(this.currentUser, "role", false) === "head-commission" ||
        this.$_.get(this.currentUser, "role", false) === "regional-director" ||
        this.$_.get(this.currentUser, "role", false) === "admin" ||
        !![25, 1879].includes(this.currentUser.id) ||
        false
      );
    },
    Local2GlobalsStatusesName() {
      return {
        0: this.$t("Создан"),
        1: this.$t("На расмотрении"),
        2: this.$t("Передано в Экспертный совет"),
        5: this.$t("Подтвержден"),
        3: this.$t("Отказано в рассмотрении"),
        4: this.$t("Возвращено на доработку"),
      };
    },
    DebtResidentsType() {
      return {
        1: "1% отчисление",
        2: "Виртуальный офис",
        3: "OSS хизмати",
      }
    },
    ZeroRiskStatusesName() {
      return {
        0: this.$t("Создан"),
        1: this.$t("Дирекция рассматривает"),
        2: this.$t("Подтвержден"),
        3: this.$t("Передано в Экспертный совет"),
        4: this.$t("Возвращено на доработку"),
        7: this.$t("Комиссия подтвердила"),
        8: this.$t("Комиссия отказалась"),
      };
    },
    authRole() {
      return this.currentUser ? this.currentUser["role"] : "";
    },
    loadRunning() {
      return this.loadRunningGetter < 0 ? 0 : this.loadRunningGetter;
    },
    currentEmployee() {
      return this.currentUser ? this.currentUser["employee"] : null;
    },
    isHrm() {
      return this.currentUser ? this.currentUser["role"] == "hr" : false;
    },
    isEmployee() {
      return this.currentUser ? this.currentUser["role"] == "employee" : false;
    },
    isAdmin() {
      return this.currentUser ? this.currentUser["role"] == "admin" : false;
    },
    isDev() {
      return process.env.NODE_ENV == "development";
    },
    isCommission() {
      if (this.currentUser && this.currentUser.rights) {
        if (
          this.currentUser.rights.local2global.actions &&
          this.currentUser.rights.local2global.actions.length
        ) {
          return (
            this.currentUser.rights.local2global.actions.indexOf("commission") >=
            0
          );
        }
      }
      return false;
    },
    isActiveMember() {
      return this.currentMember ? this.currentMember.status == 1 : false;
    },
    days() {
      var arr = [];
      for (let index = 1; index < 31; index++) {
        arr.push(index);
      }
      return arr;
    },
    monthsList() {
      return [
        { id: 1, name: "Январь" },
        { id: 2, name: "Февраль" },
        { id: 3, name: "Март" },
        { id: 4, name: "Апрель" },
        { id: 5, name: "Май" },
        { id: 6, name: "Июнь" },
        { id: 7, name: "Июль" },
        { id: 8, name: "Август" },
        { id: 9, name: "Сентябрь" },
        { id: 10, name: "Октябрь" },
        { id: 11, name: "Ноябрь" },
        { id: 12, name: "Декабрь" },
      ];
    },
    orderCounter() {
      return (index) => (((this.pagination.page - 1) || 0) * (this.pagination.limit || 0)) + index + 1;
    },
  },
  created() {
    this.screenWidth = window.innerWidth;
    window.addEventListener("resize", this.resizeScreen);
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeScreen);
  },
  methods: {
    ...mapActions({
      setPrevRoute: "app/setPrevRoute",
    }),
    async copyName(textToCopy) {
      try {
        await navigator.clipboard.writeText(textToCopy);
        this.$notify({
          title: this.$t('Скопировано'),
          type: 'success'
        });
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    },
    resizeScreen() {
      this.screenWidth = window.innerWidth;
    },
    cloneObject(object) {
      return utilsCloneObject(object);
    },
    orderBy(data, field, type = "asc", byLength = false) {
      return utilsOrderBy(data, field, type, byLength);
    },
    changeQuery(key, value) {
      let query = { ...{}, ...this.$route.query };
      if (value) {
        if (query[key] != value) {
          query[key] = value;
          this.$router.push({
            name: this.$route.name,
            params: this.$route.params,
            query,
          });
        }
      } else {
        delete query[key];
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query,
        });
      }
    },
    openFile(base64, filename) {
      let link = document.createElement("a");
      link.href = base64;
      link.download = filename;
      // link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    isCheckSlug(id, slug) {
      if (this.formOptions && this.formOptions.length > 0) {
        var model = this.formOptions.find(item => item.id == id);
        if (model) return model.slug == slug;
      }
      return false;
    },
    getTreeForSelectTree(list, parent_id) {
      if (list && list.length) {
        if (parent_id) {
          return list.filter(child => child.parent_id == parent_id).map(item => {
            return {
              parent_id: item.parent_id,
              value: item.id,
              label: this.$options.filters.t(item),
              children: this.getTreeForSelectTree(list, item.id),
            }
          })
        } else {
          return list.filter(parent => !parent.parent_id).map(item => {
            return {
              parent_id: item.parent_id,
              value: item.id,
              label: this.$options.filters.t(item),
              children: this.getTreeForSelectTree(list, item.id),
            }
          })
        }
      }
      return [];
    }
    ,
    changeQuery(key, value) {
      let query = { ...{}, ...this.$route.query };
      if (value) {
        if (query[key] != value) {
          query[key] = value;
          this.$router.push({
            name: this.$route.name,
            params: this.$route.params,
            query,
          });
        }
      } else {
        if (query[key]) {
          delete query[key];
          this.$router.push({
            name: this.$route.name,
            params: this.$route.params,
            query,
          });
        }
      }
    },
    getMonthName(date) {
      const monthNames = [
        this.$t("Январь"),
        this.$t("Февраль"),
        this.$t("Март"),
        this.$t("Апрель"),
        this.$t("Май"),
        this.$t("Июнь"),
        this.$t("Июль"),
        this.$t("Август"),
        this.$t("Сентябрь"),
        this.$t("Октябрь"),
        this.$t("Ноябрь"),
        this.$t("Декабрь"),
      ];
      var result = new Date(date);
      return monthNames[result.getMonth()];
    },
    getDay(date) {
      var result = new Date(date);
      return result.getDate();
    },
    getYear(date) {
      var result = new Date(date);
      return result.getFullYear();
    },
    getYearRu(n) {
      let str1 = this.$t("год");
      let str2 = this.$t("года");
      let str5 = this.$t("лет");
      return n % 10 == 1 && n % 100 != 11
        ? str1
        : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
          ? str2
          : str5;
    },
    getAge(n) {
      let str1 = this.$t("год в");
      let str2 = this.$t("года в");
      let str5 = this.$t("лет в");
      return n % 10 == 1 && n % 100 != 11
        ? str1
        : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
          ? str2
          : str5;
    },
    getMonthRu(n) {
      let str1 = this.$t("месяц");
      let str2 = this.$t("месяца");
      let str5 = this.$t("месяцев");
      return n % 10 == 1 && n % 100 != 11
        ? str1
        : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
          ? str2
          : str5;
    },
    even(x) {
      if (Number.isInteger(x) == true) {
        return x % 2 == 0;
      } else {
        return undefined;
      }
    },
    getIsHackathonDay() {
      if (this.hackathonStartDate) {
        var currentDate = this.$moment(this.$moment().format("YYYY-MM-DD"));
        var currentDateDiff = currentDate.diff(
          this.$moment(this.hackathonStartDate),
          "days"
        );
        return currentDateDiff < 7 && currentDateDiff >= 0;
      }
      return false;
    },
    getTotalBalls(scores) {
      var total = 0;
      if (scores && scores.length) {
        scores.forEach((score) => {
          if (score.calc_percent) {
            total += score.ball * score.calc_percent;
          } else {
            total += score.ball;
          }
        });
      }
      return total.toFixed(2);
    },
    getRoleT(role) {
      switch (role) {
        case "jury":
          return this.$t("Жюри неотобранные");
        case "jury-main":
          return this.$t("Жюри отобранные");
        default:
          return this.$t(role);
      }
    },
    regionNameBySoato(soato) {
      var model = this.regions?.find((item) => item.soato == soato);
      if (model) {
        switch (this.$i18n.locale) {
          case "uz":
            return model.title_cl;
          case "oz":
            return model.title_uz;
          case "en":
            return model.title_en;
          default:
            return model.title;
        }
      }
      return "";
    },
    getAbb(text) {
      var res = "";
      if (text && text.length) {
        var textSplit = text.split(" ");
        res = Array.from(textSplit[0])[0];
        if (textSplit.length > 1) {
          res += Array.from(textSplit[1])[0];
        }
      }
      return res;
    },
    getDayRu(n) {
      let str1 = this.$t("день");
      let str2 = this.$t("дня");
      let str5 = this.$t("дней");
      return this.getNSTextRu(n, str1, str2, str5);
    },
    getNSTextRu(n, str1, str2, str5) {
      return n % 10 == 1 && n % 100 != 11
        ? str1
        : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
          ? str2
          : str5;
    },
    getTree(list, parent_id) {
      if (list && list.length) {
        list.map((item) => {
          item["has_parent"] = !!item["parent_id"];
          if (item["parent_id"]) {
            var parent = list.find((i) => i.id == item["parent_id"]);
            item["has_parent"] = !!parent;
          }
          return item;
        });
        if (parent_id) {
          return list
            .filter((child) => child.parent_id == parent_id)
            .map((item) => {
              return {
                value: String(item.id),
                label: item.name,
                filial_id: item.filial_id,
                children: this.getTree(list, item.id),
              };
            });
        } else {
          return list
            .filter((parent) => !parent.has_parent)
            .map((item) => {
              return {
                value: String(item.id),
                label: item.name,
                filial_id: item.filial_id,
                children: this.getTree(list, item.id),
              };
            });
        }
      }
      return [];
    },
    getExtensionFromFileName(filename) {
      let filenameArr = filename.split(".");
      if (!filenameArr.slice(-1)[0]) return filename;
      return filenameArr.slice(-1)[0];
    },
    getFullPath(path, system) {
      return `${process.env.VUE_APP_PUBLIC}/${system}/storage/${path}`;
    },
    can(system, action) {
      console.log(this.currentUser.rights);
      if (this.currentUser && this.currentUser.rights && this.currentUser.rights[system]) {
        if (action == "*") {
          return true;
        }
        if (this.currentUser.rights[system]["actions"]) {
          return !(
            this.currentUser.rights[system]["actions"].indexOf(action) < 0
          );
        }
      }
      return false;
    },
    getRightStructureIds(system) {
      if (this.currentUser.rights && this.currentUser.rights[system]) {
        return this.currentUser.rights[system]["structure_ids"];
      }
      return [];
    },
    intArrToArr(intArr) {
      var arr = JSON.parse(String(intArr).replace("{", "[").replace("}", "]"));
      return arr ? arr : [];
    },
    isOther(id) {
      if (this.formOptions && this.formOptions.length > 0) {
        var model = this.formOptions.find((item) => item.id == id);
        if (model) return model.slug == "other";
      }
      return false;
    },
    fullPath(path) {
      return process.env.VUE_APP_FILE_API + path;
    },

    isDirector() {
      return this.currentUser ? this.currentUser['role'] == 'director' : false;
    },
    getRegion(id) {
      let el = this.regions?.find((el) => +el.soato === +id);
      switch (this.$i18n.locale) {
        case "uz":
          return el?.title_cl;
        case "oz":
          return el?.title_uz;
        case "en":
          return el?.title_en;
        default:
          return el?.title;
      }
    },
  },
};
