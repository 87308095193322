import { method } from "@/api/api";
import { getOSS, getOSSes, storeOSS, deleteOSS } from "@/api/oss"

export const actions = {
    storeOSS({ commit }, data) {
        return new Promise((resolve, reject) => {
            storeOSS(data)
                .then(res => {
                    resolve(res);
                }).catch(error => {
                    reject(error);
                });
        });
    },
    getOSSES({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.legal-help-requests.index", params)
                .then(res => {
                    if (res['data']) {
                        commit('SET_OSSES', res['data']);
                    };

                    resolve(res);
                }).catch(error => {
                    reject(error);
                });
        });
    },
    getOSS({ commit }, id) {
        return new Promise((resolve, reject) => {
            method("crm.legal-help-requests.show", { id })
                .then(res => {
                    resolve(res);
                }).catch(error => {
                    reject(error);
                });
        });
    },
    deleteOSS({ commit }, id) {
        return new Promise((resolve, reject) => {
            deleteOSS(id)
                .then(res => {
                    resolve(res);
                }).catch(error => {
                    reject(error);
                });
        });
    }
}