export const mutations = {
  SET_APPLICATIONS: (state, data) => {
    state.applications = data['data'];
    state.pagination = {
      total: data['total'],
      last_page: data['last_page'],
      page: data['current_page'],
      limit: data['per_page'],
      startIndex: ((data['current_page']-1)*data['per_page']),
    }
  },
  SET_CAN_ADD_BP: (state, data) => {
    state.canAddBP = data;
  }
};