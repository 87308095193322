export const getters = {
  RESIDENTS: (state) => state.residents,
  SET_EXPELLED_RESIDENTS: (state) => state.expelledResidents,
  ACTUAL_BP: (state) => state.actualBp,
  NOTIFICATION: (state) => state.notification,
  RESIDENT_VOICES: (state) => state.residentVoices,
  PREVIOUS_ROUTE_NAME: (state) => state.previousRouteName,
  VIRTUAL_OFFICE: (state) => state.virtualOffice,
  ZERO_RISK: (state) => state.zeroRisk,
  VIRTUAL_OFFICE_CONTRACT: (state) => state.virtualOfficeContract,
  COURSE_STUDENTS: (state) => state.courseStudents,
  COMPANY_TICKETS: (state) => state.tickets,
  CITIES: (state) => state.cities,
};
