export const state = {
  countries: [],
  regions: [],
  filials: [],
  formOptions: [],
  innList: [],
  banks: [],
  companyStatuses: [
    {
      code: 0,
      name_oz: "Faoliyat ko‘rsatayotgan xamda soliq majburiyatlariga ega",
      name_uz: "Фаолият курсатаетган хамда солик мажбуриятларига эга",
      name_en: "Valid and tax liable",
      name_ru: "Действующее и имеющее налоговое обязательство",
    },
    {
      code: 2,
      name_oz: "Банкрот деб эъон килинган",
      name_uz: "Банкрот деб эъон килинган",
      name_en: "Объявлен банкротом",
      name_ru: "Объявлен банкротом",
    },
    {
      code: 3,
      name_oz: "Тугатилган",
      name_uz: "Тугатилган",
      name_en: "Ликвидирован",
      name_ru: "Ликвидирован",
    },
    {
      code: 4,
      name_oz: "Бошка худудга утказилган",
      name_uz: "Бошка худудга утказилган",
      name_en: "Перенесен в другой регион",
      name_ru: "Перенесен в другой регион",
    },
    {
      code: 5,
      name_oz: "Корхона таркибидан булиниб чикарилган",
      name_uz: "Корхона таркибидан булиниб чикарилган",
      name_en: "Вылелен из состава предприятий",
      name_ru: "Вылелен из состава предприятий",
    },
    {
      code: 6,
      name_oz: "Бошка корхона таркибига кушилган",
      name_uz: "Бошка корхона таркибига кушилган",
      name_en: "Включен в состав другого предприятия",
      name_ru: "Включен в состав другого предприятия",
    },
    {
      code: 7,
      name_oz: "Такрорий берилганлиги сабабли епилган СТИР",
      name_uz: "Такрорий берилганлиги сабабли епилган СТИР",
      name_en: "Закрытие ИНН за повторное присвоение",
      name_ru: "Закрытие ИНН за повторное присвоение",
    },
    {
      code: 8,
      name_oz: "Уз хохиши билан тугатишга таерланаетган",
      name_uz: "Уз хохиши билан тугатишга таерланаетган",
      name_en: "Подготовлено для ликвидации по добровольному желанию",
      name_ru: "Подготовлено для ликвидации по добровольному желанию",
    },
    {
      code: 9,
      name_oz: "Солик мажбуриятига эга булмаган",
      name_uz: "Солик мажбуриятига эга булмаган",
      name_en: "Не имеющее налоговое объязательство",
      name_ru: "Не имеющее налоговое объязательство",
    },
    {
      code: 10,
      name_oz: "Солик мажбуриятига эга булмасдан тугатилган",
      name_uz: "Солик мажбуриятига эга булмасдан тугатилган",
      name_en: "Ликвидирован и не имеет налоговое обязательство",
      name_ru: "Ликвидирован и не имеет налоговое обязательство",
    },
    {
      code: 11,
      name_oz: "ВМ 327-сон карори асосида фаолият курсатмаетган",
      name_uz: "ВМ 327-сон карори асосида фаолият курсатмаетган",
      name_en: "Не действуют по ПКМ 327",
      name_ru: "Не действуют по ПКМ 327",
    },
    {
      code: 12,
      name_oz: "ВМ 327-сон карори асосида тугатиш жараенида",
      name_uz: "ВМ 327-сон карори асосида тугатиш жараенида",
      name_en: "В периоде ликвидации по ПКМ 327",
      name_ru: "В периоде ликвидации по ПКМ 327",
    },
    {
      code: 13,
      name_oz: "Банкротлик асосида тугатилган",
      name_uz: "Банкротлик асосида тугатилган",
      name_en: "Ликвидировано по банкротству",
      name_ru: "Ликвидировано по банкротству",
    },
    {
      code: 14,
      name_oz: "Хисобот топширмаган",
      name_uz: "Хисобот топширмаган",
      name_en: "Не сдавщее отчеты",
      name_ru: "Не сдавщее отчеты",
    },
    {
      code: 15,
      name_oz: "Рахбарларга жиноий иш кузгатилган",
      name_uz: "Рахбарларга жиноий иш кузгатилган",
      name_en: "Возбуждено уголовное дело на руководителя",
      name_ru: "Возбуждено уголовное дело на руководителя",
    },
    {
      code: 16,
      name_oz: "ПК-630-сон карори асосида ихтиерий тугатиш жараенида булган",
      name_uz: "ПК-630-сон карори асосида ихтиерий тугатиш жараенида булган",
      name_en: "В периоде ликвидации по ПП-630 по добровольному желанию",
      name_ru: "В периоде ликвидации по ПП-630 по добровольному желанию",
    },
    {
      code: 17,
      name_oz: "ПК-630-сон карори асосида ихтиерий тугатилган",
      name_uz: "ПК-630-сон карори асосида ихтиерий тугатилган",
      name_en: "Ликвидировано по добровольному желанию по ПП-630",
      name_ru: "Ликвидировано по добровольному желанию по ПП-630",
    },
    {
      code: 18,
      name_oz: "Солик мажбуриятига эга булмаган ва аникланмаган",
      name_uz: "Солик мажбуриятига эга булмаган ва аникланмаган",
      name_en: "Не имеющее налоговое объязательство до выяснений ",
      name_ru: "Не имеющее налоговое объязательство до выяснений ",
    },
    {
      code: 19,
      name_oz: "Хисоб раками очмасдан фаолият курсатмаетган(дехк.хуж-дан бошкалар)",
      name_uz: "Хисоб раками очмасдан фаолият курсатмаетган(дехк.хуж-дан бошкалар)",
      name_en: "Не действующее в связи не открытием расчетного счета (кроме дехканские хозяйства и др)",
      name_ru: "Не действующее в связи не открытием расчетного счета (кроме дехканские хозяйства и др)",
    },
    {
      code: 20,
      name_oz: "Барча ер майдонлари олиб куйилган фермер хужаликлар",
      name_uz: "Барча ер майдонлари олиб куйилган фермер хужаликлар",
      name_en: "Фермерское хозяйство у которого отобранны все земли ",
      name_ru: "Фермерское хозяйство у которого отобранны все земли ",
    },
    {
      code: 21,
      name_oz: "Хисоб раками очмасдан фаолият курсатмаетган дехкон хужаликлари",
      name_uz: "Хисоб раками очмасдан фаолият курсатмаетган дехкон хужаликлари",
      name_en: "Не действующее дехканское хозяйство в связи не открытием расчетного счета",
      name_ru: "Не действующее дехканское хозяйство в связи не открытием расчетного счета",
    },
    {
      code: 23,
      name_oz: "ВМ 327-сон карори асосида мажбурий тугатилган",
      name_uz: "ВМ 327-сон карори асосида мажбурий тугатилган",
      name_en: "Ликвидировано в принудительном порядке по ПКМ 327",
      name_ru: "Ликвидировано в принудительном порядке по ПКМ 327",
    },
    {
      code: 24,
      name_oz: "Бошка тугутилганлар (13, 17,  23 дан бошка)",
      name_uz: "Бошка тугутилганлар (13, 17,  23 дан бошка)",
      name_en: "Ликвидировано в других случаях (кроме 13, 27, 23) ",
      name_ru: "Ликвидировано в других случаях (кроме 13, 27, 23) ",
    },
    {
      code: 25,
      name_oz: "Рахбарларга жиноий иш кузгатилган - ташкилот фаолият кўрсатмокда",
      name_uz: "Рахбарларга жиноий иш кузгатилган - ташкилот фаолият кўрсатмокда",
      name_en: "Действующее предприятия - возбуждено уголовное дело руководителю",
      name_ru: "Действующее предприятия - возбуждено уголовное дело руководителю",
    },
    {
      code: 26,
      name_oz: "Рахбарларга жиноий иш кузгатилган - фаолият кўрсатмаётган ташкилот ",
      name_uz: "Рахбарларга жиноий иш кузгатилган - фаолият кўрсатмаётган ташкилот ",
      name_en: "Не действующее предприятие - возбуждено уголовное дело руководителю",
      name_ru: "Не действующее предприятие - возбуждено уголовное дело руководителю",
    }
  ],
}
