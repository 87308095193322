import { method } from "@/api/api";

export const actions = {
  list({ commit }, params) {
    return new Promise((resolve, reject) => {
      method('crm.residents.offer-signature-verification', params)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_LIST", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
