import { method } from "@/api/api";
// import { exportExcel, getReportComparisionStatistics, getReportComparisions, searchResidentByName } from "@/api/comparisionReports";

export const actions = {
  getReportComparisions({commit}, params) {
    return new Promise((resolve, reject) => {
      method("crm.report-comparison.index", params)
        .then(res => {
          commit('SET_REPORT_COMPARISIONS', res['data']);
          resolve(res);
        }).catch(error => {
          reject(error);
        });
    });
  },
  searchResidentByName({commit}, params) {
    return new Promise((resolve, reject) => {
      method("crm.report-comparison.search-by-name", params)
      .then(res => {
        resolve(res);
      }).catch(error => {
        reject(error);
      });
    });
  },
  getReportComparisionStatistics({commit}, params) {
    return new Promise((resolve, reject) => {
      method("crm.report-comparison.report-statistics", params)
        .then(res => {
          resolve(res);
        }).catch(error => {
          reject(error);
        });
    });
  },
  exportExcel({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.report-comparison.excel-export", params)
        .then(res => {
          resolve(res);
        }).catch(error => {
          reject(error);
        });
    });
  }
};
