import request from "@/utils/request";
import { method } from "@/api/api";

export const actions = {
    storeDebtResidents({ commit }, data) {
        return method("crm.resident-debt.store-excel", data);
    },
    getDebtResidents({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.resident-debt.get-all", params)
                .then((res) => {
                    if (res["success"] && res["data"]) {
                        commit("SET_RESIDENTS", res["data"]);
                    }
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
