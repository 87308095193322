import regions from "@/utils/regions";
export const mutations = {
  SET_QUARTER_REPORTS: (state, data) => {
    state.quarterReports = regions.map((region) => {
      return { region, ...data.find((item) => item.soato == region.soato) };
    });
  },
  SET_ITPARK_REPORTS: (state, data) => {
    state.itParkReports = regions.map((region) => {
      return { region, ...data.find((item) => item.soato == region.soato) };
    });
  },
  SET_APPLICATION_PROCESSING_TIMES: (state, data) => {
    state.applicationProcessingTimes.items = data.data;
    state.applicationProcessingTimes.pagination = {
      total: +data["total"],
      last_page: data["last_page"],
      page: +data["current_page"],
      limit: data["per_page"],
      startIndex: (data["currentPage"] - 1) * data["per_page"],
    };
  },
  SET_EDUCATIONAL_COMPANIES: (state, data) => {
    state.educationalCompanies = data["results"];
    state.educationalCompaniesPagination = {
      total: +data["totalPages"],
      last_page: data["last_page"],
      page: +data["currentPage"],
      limit: data["per_page"],
      startIndex: (data["currentPage"] - 1) * data["per_page"],
    };
  },
  SET_QUARTER_REPORTS_BY_COMPANIES: (state, data) => {
    state.quarterReportsByCompanies.items = data["data"];
    state.quarterReportsByCompanies.pagination = {
      total: data["total"],
      last_page: data["last_page"],
      page: data["current_page"],
      limit: data["per_page"],
      startIndex: (data["current_page"] - 1) * data["per_page"],
    };
  },
  SET_QUARTER_REPORTS_BY_COMPANY: (state, data) => {
    // Create a map of regions keyed by soato for efficient lookup
    const regionMap = regions.reduce((acc, region) => {
      acc[region.soato] = region;
      return acc;
    }, {});

    state.quarterReportsByCompany.items = data.data.map((item) => {
      // Get the corresponding region from the regionMap
      const region = regionMap[item.soato];
      // Return a new object containing the original item and the region
      return { ...item, region };
    });

    state.quarterReportsByCompany.pagination = {
      total: data["total"],
      last_page: data["last_page"],
      page: data["current_page"],
      limit: data["per_page"],
      startIndex: (data["current_page"] - 1) * data["per_page"],
    };
  },

  SET_AUDIT_REPORTS: (state, data) => {
    state.auditReports.items = data["data"];
    state.auditReports.pagination = {
      total: data["total"],
      last_page: data["last_page"],
      page: data["current_page"],
      limit: data["per_page"],
      startIndex: (data["current_page"] - 1) * data["per_page"],
    };
  },

  SET_RESIDENT_INDICATORS: (state, data) => {
    state.residentIndicators.items = data["results"];
    state.residentIndicators.pagination = {
      total: +data["total_records"],
      // last_page: +data['last_page'],
      page: +data["currentPage"],
      limit: +data["perPage"],
      // totalRecords: +data['total_records'],
      startIndex: (+data["currentPage"] - 1) * +data["perPage"],
    };
  },
  SET_MONTHLY_INCOMES_BY_COMPANIES: (state, data) => {
    state.monthlyIncomesByCompanies.summa_amount = data["summa_amount"];
    state.monthlyIncomesByCompanies.summa_export = data["summa_export"];
    state.monthlyIncomesByCompanies.items = data["data"]["data"];
    state.monthlyIncomesByCompanies.pagination = {
      total: data["data"]["total"],
      last_page: data["data"]["last_page"],
      page: data["data"]["current_page"],
      limit: data["data"]["per_page"],
      startIndex: (data["data"]["current_page"] - 1) * data["data"]["per_page"],
    };
  },
  SET_MONTHLY_INCOMES(state, data) {
    state.monthlyIncomes = data;
  },
};
