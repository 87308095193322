// import {
//   appeals,
//   storeAppeal,
//   confirm,
//   rejectAppeal,
//   showAppeal,
//   updateAppeal,
//   reply,
//   exportExcel,
//   getAppealsByCompanyTin,
//   searchResidentByName
// } from '@/api/appeals';

import { method } from "@/api/api";

export const actions = {
  getAppeals({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.appeals.index", params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_APPEALS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  showAppeal({ commit }, id) {
    return new Promise((resolve, reject) => {
      method("crm.appeals.show", { id })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  confirm({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("crm.appeals.confirm", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  rejectAppeal({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("crm.appeals.reject", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  reply({ commit }, data) {
    return method("crm.appeals.reply", data);
  },

  getAppealsByCompanyTin({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.appeals.get-by-company", params)
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  searchResidentByName({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.appeals.search-company-name", params)
        .then(res => {
          resolve(res);
        }).catch(error => {
          reject(error);
        });
    });
  },
  getAppealsByCompanyTin({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.appeals.get-by-company", params)
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
