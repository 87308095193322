export const mutations = {
  SET_EXPORT_OPINION: (state, data) => {
    state.expertOpinion = data;
  },
  SET_EXPORT_OPINION_SHOW: (state, data) => {
    state.expertOpinionShow = data;
  },
  ADD_EXPERT_OPINION(state, opinion) {
    state.expertOpinions.push(opinion);
  },
  SET_COMPANIES(state, companies) {
    state.companies = companies;
  },
  SET_EXPORT_OPINION_DOCX(state, data) {
    state.exportOpinionDocx = data;
  },
  SET_PAGE: (state, data) => {
    if (data) {
      state.list = data["data"];
      state.pagination = {
        total: data["total"],
        from: data["from"],
        to: data["to"],
        last_page: data["last_page"],
        page: data["current_page"],
        limit: data["per_page"],
        startIndex: (data["current_page"] - 1) * data["per_page"] + 1,
      };
    } else {
      state.list = [];
      state.pagination = null;
    }
  },
};
// 930051896