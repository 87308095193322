export const mutations = {
  SET_RESIDENTS: (state, items) => {
    state.residents.items = items.data;
    state.residents.pagination = {
      total: items.total,
      page: items.current_page,
      limit: items.per_page,
      show: items.total > items.per_page,
      startIndex: (items["current_page"] - 1) * items["per_page"],
    };
  },
  SET_EXPELLED_RESIDENTS:(state,items) =>{
    state.expelledResidents.items = items.data;
    state.expelledResidents.pagination = {
      total: items.total,
      page: +items.currentPage,
      limit: items.perPage,
      show: items.total > items.perPage,
      startIndex: (items["currentPage"] - 1) * items["perPage"],
    };
  },
  SET_ACTUAL_BP: (state, data) => {
    state.actualBp = data;
  },
  SET_CITIES: (state, data) => {
    state.cities = data;
  },
  SET_NOTIFICATION: (state, data) => {
    state.notification = data;
  },
  //resident voices
  SET_RESIDENT_VOICES: (state, items) => {
    state.residentVoices.items = items.data;
    state.residentVoices.pagination = {
      total: items.total,
      page: items.current_page,
      limit: items.per_page,
      show: items.total > items.per_page,
      startIndex: (items["current_page"] - 1) * items["per_page"],
    };
  },
  SET_FROM_ROUTE_NAME(state, data) {
    state.previousRouteName = data;
  },
  SET_VIRTUAL_OFFICE(state, data) {
    state.virtualOffice.items = data["data"];
    state.virtualOffice.pagination = {
      total: data.total,
      page: data.current_page,
      limit: data.per_page,
      show: data.total > data.per_page,
      startIndex: (data["current_page"] - 1) * data["per_page"],
    };
  },
  SET_ZERO_RISK(state, data) {
    state.zeroRisk.items = data["data"];
    state.zeroRisk.pagination = {
      total: data.total,
      page: data.current_page,
      limit: data.per_page,
      show: data.total > data.per_page,
      startIndex: (data["current_page"] - 1) * data["per_page"],
    };
  },
  SET_VIRTUAL_OFFICE_CONTRACT(state, data) {
    state.virtualOfficeContract.items = data["data"];
    state.virtualOfficeContract.pagination = {
      total: data.total,
      page: data.current_page,
      limit: data.per_page,
      show: data.total > data.per_page,
      startIndex: (data["current_page"] - 1) * data["per_page"],
    };
  },

  SET_COURSE_STUDENTS(state, data) {
    state.courseStudents.items = data["data"];
    state.courseStudents.pagination = {
      total: data.total,
      page: data.current_page,
      limit: data.per_page,
      show: data.total > data.per_page,
      startIndex: (data["current_page"] - 1) * data["per_page"],
    };
  },

  SET_COMPANY_TICKETS(state, { data }) {
    state.tickets.items = data["data"];
    state.tickets.pagination = {
      total: data.total,
      page: data.current_page,
      limit: data.per_page,
      show: data.total > data.per_page,
      startIndex: (data["current_page"] - 1) * data["per_page"],
    };
  },
};
