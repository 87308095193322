const routes = [
  {
    path: "/residents-exporters",
    name: "ResidentsExporters",
    meta: {
      middleware: "Auth",
    },
    component: () => import("@/views/residentsExporters/Index.vue"),
  },
  {
    path: "/expelled-residents",
    name: "expelledResidents",
    meta: {
      middleware: "Auth",
    },
    component: () => import("@/views/expelledResidents/Index.vue"),
  },
  {
    path: "/signature-verification",
    name: "SignatureVerification",
    meta: {
      middleware: "Auth",
    },
    component: () => import("@/views/signatureVerification/Index.vue"),
  },
  {
    path: "/financial-indicators",
    name: "FinancialIndicators",
    meta: {
      middleware: "Auth",
      nav: "FinancialIndicators",
    },
    component: () => import("@/views/financialIndicators/Index.vue"),
  },
  {
    path: "/financial-indicators/create",
    name: "CreateFinancialIndicator",
    meta: {
      middleware: "Auth",
      nav: "FinancialIndicators",
    },
    component: () => import("@/views/financialIndicators/Form.vue"),
  },
  {
    path: "/financial-indicators/edit/:year/:quarter",
    name: "EditFinancialIndicator",
    meta: {
      middleware: "Auth",
      nav: "FinancialIndicators",
    },
    component: () => import("@/views/financialIndicators/Form.vue"),
  },
  {
    path: "/financial-indicators/:year",
    name: "ShowFinancialIndicator",
    meta: {
      middleware: "Auth",
      nav: "FinancialIndicators",
    },
    component: () => import("@/views/financialIndicators/Show.vue"),
  },
  {
    path: "/statistics-by-external",
    name: "StatisticsByExternal",
    meta: {
      middleware: "Auth",
      nav: "StatisticsByExternal",
    },
    component: () => import("@/views/statisticsByExternal/Index.vue"),
  },

  {
    path: "/requests",
    name: "Request",
    meta: {
      middleware: "Auth",
      nav: "Request",
    },
    component: () => import("@/views/requests/Index.vue"),
  },
  {
    path: "/requests/:id",
    name: "ShowRequest",
    meta: {
      middleware: "Auth",
      nav: "Request",
    },
    component: () => import("@/views/requests/Show.vue"),
  },
  {
    path: "/course-students",
    name: "CourseStudent",
    meta: {
      middleware: "Auth",
      nav: "Education",
    },
    component: () => import("@/views/tutorials/courseStudents/Index.vue"),
  },

  {
    path: "/course-students/:uuid",
    name: "ShowCourseStudent",
    meta: {
      middleware: "Auth",
      nav: "Education",
    },
    component: () => import("@/views/tutorials/courseStudents/Show.vue"),
  },

  {
    path: "/oss",
    name: "OSS",
    meta: { middleware: "Auth", nav: "OSS" },
    component: () => import("@/views/OSS/Index.vue"),
  },
  {
    path: "/oss/:id",
    name: "ShowOSS",
    meta: { middleware: "Auth", nav: "OSS" },
    component: () => import("@/views/OSS/Show.vue"),
  },

  {
    path: "/virtual-office",
    name: "VirtualOffice",
    meta: {
      middleware: "Auth",
      nav: "VirtualOffice",
    },
    component: () => import("@/views/virtualOffice/Index.vue"),
  },
  {
    path: "/virtual-office/application/:id",
    name: "SHowVirtualOffice",
    meta: {
      middleware: "Auth",
      nav: "VirtualOffice",
    },
    component: () => import("@/views/virtualOffice/Show.vue"),
  },

  {
    path: "/virtual-office/contract",
    name: "VirtualOfficeContract",
    meta: {
      middleware: "Auth",
      nav: "VirtualOffice",
    },
    component: () => import("@/views/virtualOfficeContract/Index.vue"),
  },
  {
    path: "/virtual-office/contract/create",
    name: "CreateVirtualOfficeContract",
    meta: {
      middleware: "Auth",
      nav: "VirtualOffice",
    },
    component: () => import("@/views/virtualOfficeContract/Form.vue"),
  },
  {
    path: "/virtual-office/edit-contract/:id",
    name: "UpdateVirtualOfficeContract",
    meta: {
      middleware: "Auth",
      nav: "VirtualOffice",
    },
    component: () => import("@/views/virtualOfficeContract/Form.vue"),
  },
  {
    path: "/virtual-office/contract/:id",
    name: "VirtualOfficeContractShow",
    meta: {
      middleware: "Auth",
      nav: "VirtualOffice",
    },
    component: () => import("@/views/virtualOfficeContract/Show.vue"),
  },
  {
    path: "/appeals",
    name: "Appeals",
    meta: {
      middleware: "Auth",
      nav: "Appeals",
    },
    component: () => import("@/views/appeals/Index"),
  },
  {
    path: "/appeals/:id",
    name: "ShowAppeal",
    meta: {
      middleware: "Auth",
      nav: "Appeals",
    },
    component: () => import("@/views/appeals/Show"),
  },

  {
    path: "analytics",
    name: "Analytics",
    meta: { nav: "analytics" },
    component: () => import("@/views/analytics/RegionTables.vue"),
  },
  {
    path: "analytics/inappropriate-activities",
    name: "InappropriateActivities",
    meta: { nav: "analytics" },
    component: () => import("@/views/analytics/InappropriateActivities.vue"),
  },
  {
    path: "analytics/invoices-by-company",
    name: "ResidentsNonCompliantCodes",
    meta: {
      nav: "analytics",
      middleware: "Auth",
    },
    component: () => import("@/views/analytics/ResidentsNonCompliant.vue"),
  },
  {
    path: "analytics/code",
    name: "AnalyticsCodeList",
    meta: { nav: "analytics" },
    component: () => import("@/views/analytics/ListOfCodes.vue"),
  },
  {
    path: "/invoices",
    name: "Invoices",
    meta: {
      middleware: "Auth",
      nav: "Invoice",
    },
    component: () => import("@/views/invoices/Index.vue"),
  },
  {
    path: "/invoices/:tin",
    name: "ShowInvoices",
    meta: {
      middleware: "Auth",
      nav: "Invoice",
    },
    component: () => import("@/views/invoices/Show.vue"),
  },
  {
    path: "/invoices/:tin/:uuid",
    name: "ShowInvoice",
    meta: {
      middleware: "Auth",
      nav: "Invoice",
    },
    component: () => import("@/views/invoices/InvoiceShow.vue"),
  },

  {
    path: "/statistics",
    name: "Statistics",
    meta: {
      middleware: "Auth",
      nav: "Statistics",
    },
    redirect: { name: 'StatisticsQuarterReports' },
    component: () => import("@/views//statistics/QuarterReports"),
  },
  {
    path: "/statistics/quarter-reports",
    name: "StatisticsQuarterReports",
    meta: {
      middleware: "Auth",
      nav: "Statistics",
    },
    component: () => import("@/views//statistics/QuarterReports"),
  },
  {
    path: "/statistics/comparison-residents",
    name: "ComparisonResidents",
    meta: {
      middleware: "Auth",
      nav: "Statistics",
    },
    component: () => import("@/views//statistics/ComparisonResidents.vue"),
  },
  {
    path: "/statistics/processing-times",
    name: "ApplicationProcessingTimes",
    meta: {
      middleware: "Auth",
      nav: "Statistics",
    },
    component: () =>
      import("@/views//statistics/ApplicationProcessingTimes"),
  },
  {
    path: "/statistics/quarter-reports-external",
    name: "StatisticsQuarterReportsExternal",
    meta: {
      middleware: "Auth",
      nav: "Statistics",
    },
    component: () => import("@/views//statistics/QuarterReportsExternal"),
  },
  {
    path: "/statistics/itpark-reports",
    name: "ItParkReports",
    meta: {
      middleware: "Auth",
      nav: "Statistics",
    },
    component: () => import("@/views//statistics/ItPark"),
  },

  {
    path: "/statistics/business-plan-application",
    name: "StatisticsBusinessPlanApplications",
    meta: {
      middleware: "Auth",
      nav: "Statistics",
    },
    component: () =>
      import("@/views//statistics/BusinessPlanApplications.vue"),
  },

  {
    path: "/statistics/educational-companies",
    name: "StatisticsEducationalCompanies",
    meta: {
      middleware: "Auth",
      nav: "Statistics",
    },
    component: () => import("@/views//statistics/EducationalCompanies.vue"),
  },

  {
    path: "/statistics/quarter-report-by-residents",
    name: "StatisticsQuarterReportsByCompanies",
    meta: {
      middleware: "Auth",
      nav: "Statistics",
    },
    component: () =>
      import("@/views//statistics/QuarterReportsByCompanies.vue"),
  },

  {
    path: "/statistics/audit-reports",
    name: "StatisticsAuditReports",
    meta: {
      middleware: "Auth",
      nav: "Statistics",
    },
    component: () => import("@/views//statistics/AuditReports.vue"),
  },
  {
    path: "/statistics/monthly-incomes-by-residents",
    name: "StatisticsMonthlyIncomesByCompanies",
    meta: {
      middleware: "Auth",
      nav: "Statistics",
    },
    component: () =>
      import("@/views//statistics/MonthlyIncomesByCompanies"),
  },
  {
    path: "/statistics/resident-indicators",
    name: "StatisticsTop30Residents",
    meta: {
      middleware: "Auth",
      nav: "Statistics",
    },
    component: () => import("@/views//statistics/Top30Residents.vue"),
  },

  {
    path: "/statistics/monthly-incomes",
    name: "StatisticsByMonthlyIncomes",
    meta: {
      middleware: "Auth",
      nav: "Statistics",
    },
    component: () => import("@/views//statistics/MonthlyIncomes"),
  },

  {
    path: "/statistics/manager-statistics",
    name: "ManagerStatistics",
    meta: {
      middleware: "Auth",
      nav: "Statistics",
    },
    component: () => import("@/views/statistics/ManagerStatistics.vue"),
  },

  {
    path: "/statistics/statistics-monitoring",
    name: "StatisticsMonitoring",
    meta: {
      middleware: "Auth",
      nav: "Statistics",
    },
    component: () => import("@/views/statistics/MonitoringStatistics.vue"),
  },

  {
    path: "/statistics/monthly-reports-data",
    name: "DataOnMonthlyReports",
    meta: {
      middleware: "Auth",
      nav: "Statistics",
    },
    component: () => import("@/views/statistics/DataOnMonthlyReports.vue"),
  },

  {
    path: "/statistics/monthly-reports-data-by-country",
    name: "DataOnMonthlyReportsByExportCountry",
    meta: {
      middleware: "Auth",
      nav: "Statistics",
    },
    component: () =>
      import("@/views/statistics/DataOnMonthlyReportsByExportCountry"),
  },

  {
    path: "/statistics/exported-business-plans",
    name: "ExportedBusinessPlans",
    meta: {
      middleware: "Auth",
      nav: "Statistics",
    },
    component: () =>
      import("@/views/statistics/ExportedBusinessPlans.vue"),
  },

  // resident
  {
    path: "residents",
    name: "RegisterOfResidents",
    meta: {
      middleware: "Auth",
      nav: "Residents",
    },
    component: () => import("@/views/residents/Residents.vue"),
  },
  {
    path: "residents/register",
    name: "Residents",
    meta: {
      middleware: "Auth",
      nav: "Residents",
    },
    component: () => import("@/views/residents/RegisterOfResidents"),
  },
  {
    path: "residents/business-plan-applications/:companyTin/:uuid",
    name: "ResidentBusinessPlanApplicationShow",
    meta: {
      middleware: "Auth",
      nav: "Residents",
    },
    component: () =>
      import("@/views/residents/components/ResidentBusinessPlanApplicationShow.vue"),
  },

  {
    path: "residents/:id",
    name: "ResidentShow",
    meta: {
      middleware: "Auth",
      nav: "Residents",
    },
    component: () => import("@/views/residents/ResidentShow.vue"),
  },

  {
    path: "/business-plan-applications",
    name: "BusinessPlanApplications",
    meta: {
      middleware: "Auth",
      nav: "BusinessPlanApplications",
    },
    component: () => import("@/views/businessPlanApplications/Index.vue"),
  },
  {
    path: "/business-plan-application/:uuid",
    name: "ShowBusinessPlanApplication",
    meta: {
      middleware: "Auth",
      nav: "BusinessPlanApplications",
    },
    component: () => import("@/views/businessPlanApplications/Show.vue"),
  },

  {
    path: "/quarterly-reports/:uuid",
    name: "ShowQuarterlyReport",
    meta: {
      middleware: "Auth",
      nav: "Reports",
    },
    component: () => import("@/views/quarterlyReports/Show.vue"),
  },
  {
    path: "/audit-reports/:uuid",
    name: "ShowAuditReport",
    meta: {
      middleware: "Auth",
      nav: "Reports",
    },
    component: () => import("@/views/auditReports/Show.vue"),
  },

  {
    path: "/business-plan-applications",
    name: "BusinessPlanApplications",
    meta: {
      middleware: "Auth",
      nav: "BusinessPlanApplications",
    },
    component: () => import("@/views/businessPlanApplications/Index.vue"),
  },
  {
    path: "/business-plan-application/:uuid",
    name: "ShowBusinessPlanApplication",
    meta: {
      middleware: "Auth",
      nav: "BusinessPlanApplications",
    },
    component: () => import("@/views/businessPlanApplications/Show"),
  },
]

export default routes;
