import { method } from "@/api/api";

export const actions = {
  reject({ commit }, uuid) {
    return method("crm.monthly-incomes.reject", uuid);
  },
  returnForIncomplete({ commit }, data) {
    return method("crm.monthly-incomes.return-for-incomplete", data);
  },
  sendReview({ commit }, data) {
    return sendReview(data);
  },
  confirm({ commit }, uuid) {
    return method("crm.monthly-incomes.confirm", uuid);
  },
  getList({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.monthly-incomes.index", params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  create({ commit }, data) {
    return store(data);
  },
  showMonthlyIncome({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.monthly-incomes.get-by-uuid", params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateMonthlyIncome({ commit }, data) {
    return update(data);
  },

  exportExcel({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("crm.monthly-incomes.export-excel", data)
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getMonthlyIncomesByCompanyTin({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.monthly-incomes.get-by-company", params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getLastSavedMonthlyIncomes({ commit }, params) {
    return new Promise((resolve, reject) => {
      getLastSavedMonthlyIncomes(params)
        .then(res => {
          resolve(res);
        }).catch(error => {
          reject(error);
        })
    })
  },
  searchResidentByName({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.monthly-incomes.search-company-name", params)
        .then(res => {
          console.log(res);
          resolve(res);
        }).catch(error => {
          reject(error);
        });
    });
  },
  returnForIncompleteByAccountant({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("crm.monthly-incomes.return-for-incomplete-by-accountant", data)
        .then(res => {
          resolve(res);
        }).catch(error => {
          reject(error);
        });
    });
  },
  confirmByAccountant({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      method("crm.monthly-incomes.confirm-by-accountant", uuid)
        .then(res => {
          resolve(res);
        }).catch(error => {
          reject(error);
        });
    });
  },

  exportAsPdf({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.monthly-incomes.export-pdf", params)
        .then(res => {
          resolve(res);
        }).catch(error => {
          reject(error);
        });
    });
  }
};
