import { method } from "@/api/api";

export const actions = {
    getStatisticsByExternal({ commit }, params) {
        return new Promise((resolve, reject) => {
            method('crm.statistics.statistics-by-external', params)
                .then((res) => {
                    if (res["success"] && res["data"]) {
                        commit("SET_STATISTICS_BY_EXTERNAL", res["data"]);
                    }
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    exportExcel({ commit }, params) {
        return method('crm.statistics.statistics-by-external-excel', params)
    }
};