const routes = [
  {
    path: '/crm',
    name: 'CRM',
    meta: { middleware: 'Auth' },
    redirect: {name: 'CRMCompanies'},
    component: () =>
    import("@/views/crm/index"),
  },{
    path: '/crm/companies',
    name: 'CRMCompanies',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/crm/companies.vue"),
  },{
    path: '/crm/leads',
    name: 'CRMLeads',
    meta: { middleware: 'Auth' },
    component: () =>
        import("@/views/crm/leads.vue"),
  },{
    path: '/crm/tasks',
    name: 'CRMTasksMain',
    redirect: {name: 'CRMTasksWorkspaces'},
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/crm/tasks/tasks"),
  },{
    path: '/crm/tasks/workspaces',
    name: 'CRMTasksWorkspaces',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/crm/tasks/workspaces"),
  },{
    path: '/crm/tasks/board/:id',
    name: 'CRMTasks',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/crm/tasks/tasks"),
  },{
    path: '/crm/cards/sources',
    name: 'CRMCardSources',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/crm/cardSources"),
  }
]

export default routes;
