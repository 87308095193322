export default [
  {
    name_uz: "IT Park",
    name_ru: "IT Park",
    name_en: "IT Park",
    name_oz: "IT Park",
    soato: 1726,
  },

  {
    name_uz: "Қорақалпоғистон Республикаси",
    name_ru: "Республика Каракалпакстан",
    name_en: "Karakalpakstan Republic",
    name_oz: "Qoraqalpog‘iston Respublikasi",
    soato: 1735,
  },
  {
    name_uz: "Бухоро вилояти",
    name_ru: "Бухарская область",
    name_en: "Bukhara Region",
    name_oz: "Buxoro viloyati",
    soato: 1706,
  },
  {
    name_uz: "Самарқанд вилояти",
    name_ru: "Самаркандская область",
    name_en: "Samarkand Region",
    name_oz: "Samarqand viloyati",
    soato: 1718,
  },
  {
    name_uz: "Навоий вилояти",
    name_ru: "Навоийская область",
    name_en: "Navoiy Region",
    name_oz: "Navoiy viloyati",
    soato: 1712,
  },
  {
    name_uz: "Андижон вилояти",
    name_ru: "Андижанская область",
    name_en: "Andijan Region",
    name_oz: "Andijon viloyati",
    soato: 1703,
  },
  {
    name_uz: "Фарғона вилояти",
    name_ru: "Ферганская область",
    name_en: "Fergana Region",
    name_oz: "Farg‘ona viloyati",
    soato: 1730,
  },
  {
    name_uz: "Сурхондарё вилояти",
    name_ru: "Сурхандарьинская область",
    name_en: "Surkhandarya Region",
    name_oz: "Surxondaryo viloyati",
    soato: 1722,
  },
  {
    name_uz: "Сирдарё вилояти",
    name_ru: "Сырдарьинская область",
    name_en: "Syrdarya Region",
    name_oz: "Sirdaryo viloyati",
    soato: 1724,
  },
  {
    name_uz: "Хоразм вилояти",
    name_ru: "Хорезмская область",
    name_en: "Khorezm Region",
    name_oz: "Xorazm viloyati",
    soato: 1733,
  },
  {
    name_uz: "Тошкент вилояти",
    name_ru: "Ташкентская область",
    name_en: "Tashkent Region",
    name_oz: "Toshkent viloyati",
    soato: 1727,
  },
  {
    name_uz: "Қашқадарё вилояти",
    name_ru: "Кашкадарьинская область",
    name_en: "Kashkadarya Region",
    name_oz: "Qashqadaryo viloyati",
    soato: 1710,
  },
  {
    name_uz: "Жиззах вилояти",
    name_ru: "Джизакская область",
    name_en: "Jizzakh Region",
    name_oz: "Jizzax viloyati",
    soato: 1708,
  },
  {
    name_uz: "Наманган вилояти",
    name_ru: "Наманганская область",
    name_en: "Namangan Region",
    name_oz: "Namangan viloyati",
    soato: 1714,
  },

  {
    name_uz: "Тошкент шаҳри",
    name_ru: "город Ташкент",
    name_en: "Tashkent City",
    name_oz: "Toshkent shahri",
    soato: 9999,
  },

];
