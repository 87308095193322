export const mutations = {
    SET_INVOICES: (state, data) => {
        state.invoices.items = data['data'];
        state.invoices.pagination = {
          total: data['total'],
          last_page: data['last_page'],
          page: data['current_page'],
          limit: data['per_page'],
          startIndex: ((data['current_page']-1)*data['per_page']),
        };   
    },
    SET_INVOICE_STATISTICS: (state, data) => {
      state.invoiceStatistics = data['data'];
    }
}