// import { completeRequest, list, returnForIncomplete, exportExcel } from "@/api/returnMonthlyReport";

import { method } from "@/api/api";

export const actions = {
    getList({ commit }, params) {
        console.log("hi")
        return new Promise((resolve, reject) => {
            method("crm.quarterly-reports.get-sent-requests-for-incomplete", params)
                .then(res => {
                    console.log("hi")
                    if (res['success'] && res['data']) {
                        commit('SET_LIST', res['data']);
                    }
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    completeRequest({ commit }, params) {
        return method("crm.quarterly-reports.respond-to-incomplete-request-by-company", params);
    },
};
