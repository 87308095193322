import { method } from '@/api/api';

export const actions = {
  countries({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.resource.countries", params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_COUNTRIES', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getRegions({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.local2global.regions", params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_REGIONS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          console.log(err)
          reject(err);
        });
    });
  },
  getCities({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.resource.cities", params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_CITIES', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getBanks({commit}) {
    return new Promise((resolve, reject) => {
      method("crm.resource.banks")
        .then(res => {
          commit('SET_BANKS', res['data']);
          resolve(res);
        }).catch(error => {
          reject(error);
        });
    });
  },
  formOptions({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.resource.form-options", params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_FORM_OPTIONS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getPositions({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.resource.form-options", params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_POSITIONS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getFilials({commit}) {
    return new Promise((resolve, reject) => {
      method("crm.resource.filials")
        .then(res => {
          commit('SET_FILIALS', res['data']);
          resolve(res);
        }).catch(error => {
          reject(error);
        });
    });
  },

  InnList({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.residents.list", params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_INN_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};
