import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import auth from "./auth";
import loader from "./loader";
import users from "./users";
import app from "./app";
import filials from "./filials";
import structures from "./structures";
import positions from "./positions";
import employees from "./employees";
import steps from "./steps";
import cards from "./cards";
import resource from "./resource";
import taskSteps from "./taskSteps";
import tasks from "./tasks";
import kpi from "./kpi";
import taskWorkspaces from "./taskWorkspaces";
import taskBoards from "./taskBoards";
import cardSources from "./cardSources";
import tickets from "./tickets";
import categories from "./categories";
import articles from "./articles";
import faqCategories from "./faqCategories";
import ticketTemplates from "./ticketTemplates";
import rights from "./rights";
import notifications from "./notifications";
import local2Global from "./local2Global";
import enteredData from "./enteredData";
import infrastructure from "./infrastructure";
import talents from "./talents";
// new
import company from "./company";
import signatureVerification from "./signatureVerification";
import bp from "./bp";
import debtResidents from "./debtResidents";
import residentsExporters from "./residentsExporters";
import zeroRisk from "./zeroRisk";
import financialIndicators from "./financialIndicators";
import statisticsByExternal from "./statisticsByExternal";
import oss from "./oss";
import appeals from "./appeals";
import requests from "./requests";
import quarterlyReports from "./quarterlyReports";
import analytics from "./analytics";
import statistics from "./statistics";
import invoices from "./invoices";
import monthlyIncomes from "./monthlyIncomes";
import auditReports from "./auditReports";
import comparisionReports from "./comparisionReports";
import returnMonthlyReport from "./returnMonthlyReport";
import returnAuditReport from "./returnAuditReport";
import returnQuarterlyReport from "./returnQuarterlyReport";
import expertOpinion from "./expertOpinion";
import marketing from "./marketing";

const store = new Vuex.Store({
  modules: {
    auth,
    loader,
    users,
    app,
    filials,
    structures,
    positions,
    employees,
    steps,
    cards,
    resource,
    taskSteps,
    tasks,
    kpi,
    taskWorkspaces,
    taskBoards,
    cardSources,
    tickets,
    categories,
    articles,
    faqCategories,
    ticketTemplates,
    rights,
    notifications,
    local2Global,
    enteredData,
    infrastructure,
    talents,
    zeroRisk,
    // new
    company,
    signatureVerification,
    bp,
    debtResidents,
    residentsExporters,
    financialIndicators,
    statisticsByExternal,
    requests,
    oss,
    appeals,
    quarterlyReports,
    analytics,
    statistics,
    invoices,
    monthlyIncomes,
    auditReports,
    comparisionReports,
    returnMonthlyReport,
    returnAuditReport,
    returnQuarterlyReport,
    expertOpinion,
    marketing,
  },
});

export default store;

const initialStateCopy = JSON.parse(JSON.stringify(store.state));

export function resetState() {
  let storeData = JSON.parse(JSON.stringify(store.state));
  initialStateCopy["classifiers"] = storeData["classifiers"];
  initialStateCopy["auth"]["is_auth"] = false;
  initialStateCopy["auth"]["user"] = null;
  initialStateCopy["auth"]["role_name"] = "";
  store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)));
}
