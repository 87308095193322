const routes =  [
  {
    path: "/admin/export-forecast",
    name: "ExportForecast",
    component: () => import("@/views/ExportForecast.vue"),
  },
  {
    path: "/admin/export-forecast/:tin/:year/show",
    name: "ExportForecast",
    component: () => import("@/views/ExportForecastShow.vue"),
  },
  {
    path: "/admin/export-forecast-region",
    name: "ExportForecastRegion",
    component: () => import("@/views/ExportForecastRegion.vue"),
  },
];  

export default routes;