import request from "@/utils/request";
import { method } from "@/api/api";

export const actions = {
    getResidentsExporters({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.resident.get-resident-exporter", params)
                .then((res) => {
                    if (res["success"] && res["data"]) {
                        commit("SET_RESIDENTS", res["data"]);
                    }
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
