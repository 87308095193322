// import { completeRequest, list, returnForIncomplete, exportExcel } from "@/api/returnMonthlyReport";

import { method } from "@/api/api";

export const actions = {
    getList({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.audit-report.get-sent-requests-for-incomplete", params)
                .then(res => {
                    if (res['success'] && res['data']) {
                        commit('SET_LIST', res['data']);
                    }
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    completeRequest({ commit }, params) {
        return method("crm.audit-report.respond-to-incomplete-request-by-company", params);
    },
    // returnForIncomplete({ commit }, params) {
    //     return method("crm.monthly-incomes.return-for-incomplete", params);
    // },
    // exportExcel({ commit }, data) {
    //     return new Promise((resolve, reject) => {
    //         method("crm.monthly-incomes.export-excel", data)
    //             .then(res => {
    //                 resolve(res);
    //             })
    //             .catch(error => {
    //                 reject(error);
    //             });
    //     });
    // },
};
