import { method } from "@/api/api";

export const actions = {
  searchMonthlyIncomesResidentByName({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.monthly-incomes.search-company-name", params)
        .then(res => {
          resolve(res);
        }).catch(error => {
          reject(error);
        });
    });
  },

  getQuarterReportStatistics({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.statistics.quarter-report-statistics", params)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_QUARTER_REPORTS", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getQuarterReportStatisticsByCompany({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.statistics.quarter-report-statistics-by-company", params)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_QUARTER_REPORTS_BY_COMPANY", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getApplicationProcessingTimes({ commit }, params) {
    return new Promise((resolve, reject) => {
      getApplicationProcessingTimes(params)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_APPLICATION_PROCESSING_TIMES", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getApplicationProcessingTimesPdf(params) {
    return new Promise((resolve, reject) => {
      getApplicationProcessingTimesPdf(params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getItParkReports({ commit }, params) {
    return new Promise((resolve, reject) => {
      getItParkReports(params)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_ITPARK_REPORTS", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  exportExcel({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.statistics.quarter-report-statistics-excel", params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  exportExcelByCompany({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.statistics.quarter-report-statistics-by-company-excel", params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  exportExcelItPark({ commit }, params) {
    return new Promise((resolve, reject) => {
      exportExcelItPark(params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getEducationalCompanies({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.quarterly-reports.get-course-statistics", params)
        .then((res) => {
          if (res["data"]) {
            commit("SET_EDUCATIONAL_COMPANIES", res["data"]);
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  exportEducationCompaniesAsExcel({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.quarterly-reports.excel-export-statistics", params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getStatisticsByResidents({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.quarterly-reports.statistics-by-residents", params)
        .then((res) => {
          commit("SET_QUARTER_REPORTS_BY_COMPANIES", res["data"]);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  exportStatisticsByResidentsExcel({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.quarterly-reports.statistics-by-residents-excel", params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAuditReports({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.audit-report.statistics", params)
        .then((res) => {
          commit("SET_AUDIT_REPORTS", res["data"]);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  exportStatisticsAuditReportsExcel({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.audit-report.statistics-excel", params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  top30Residents({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.resident-statistics.top-thirty-residents", params)
        .then((res) => {
          commit("SET_RESIDENT_INDICATORS", res["data"]);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  exportTop30ResidentsExcel({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.resident-statistics.top-thirty-residents-excel", params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getStatisticsByBusinessPlanApplication({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.bp-app.statistics-by-region", params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  exportExcelByBusinessPlanApplication({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.bp-app.statistics-by-region-excel", params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  searchResidentByNameForResidentIndicators({ commit }, params) {
    return new Promise((resolve, reject) => {
      searchResidentByNameForResidentIndicators(params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getManagerStatistics({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.statistics.manager-statistics-by-business-plan-application", params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getManagerStatisticsByMonthly({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.statistics.manager-statistics-by-monthly-income", params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getDataOnMonthlyReports({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.monthly-incomes.export-statistics-by-industry", params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getDataOnMonthlyReportsByExportCountry({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.monthly-incomes.export-statistics-by-country", params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getExportedDataBusinessPlans({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.bp-app.finance-statistics", params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getBusinessPlansByCompany({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.bp-app.finance-statistics-by-company", params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getResidentsComparisonStatics({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.resident-statistics.resident-selected-period-comparison-statistics", params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchResidentByNameForResidentIndicators({ commit }, params) {
    return new Promise((resolve, reject) => {
      return method("crm.quarterly-reports.search-company-name", params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getMonthlyIncomesByResidents({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.monthly-incomes.table-by-company", params)
        .then((res) => {
          resolve(res);
          commit("SET_MONTHLY_INCOMES_BY_COMPANIES", res["data"]);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  exportExcelMonthlyIncomesByResidents({ }, params) {
    return new Promise((resolve, reject) => {
      method("crm.monthly-incomes.table-by-company-excel", params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  statisticsMonthlyIncomes({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.monthly-incomes.statistics-by-region", params)
        .then((res) => {
          commit("SET_MONTHLY_INCOMES", res["data"]);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  statisticsMonthlyIncomesDownloadAsExcel({ }, params) {
    return new Promise((resolve, reject) => {
      method("crm.monthly-incomes.statistics-by-region-excel", params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
