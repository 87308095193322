export const state = {
  previousRouteName: "",
  residents: {
    items: [],
    pagination: null,
  },
  expelledResidents: {
    items: [],
    pagination: null,
  },
  actualBp: null,
  cities: [],
  notification: null,

  residentVoices: {
    items: [],
    pagination: null,
  },
  virtualOffice: {
    items: [],
    pagination: null,
  },
  zeroRisk: {
    items: [],
    pagination: null,
  },
  virtualOfficeContract: {
    items: [],
    pagination: null,
  },
  courseStudents: {
    items: [],
    pagination: null,
  },
  tickets: {
    items: [],
    pagination: null,
  },
};
