import { orderBy } from '@/utils/index'
import i18n from "@/plugins/i18n";
export const mutations = {
  SET_COUNTRIES: (state, data) => {
    state.countries = orderBy(data, 'name_'+i18n.locale);
  },
  SET_REGIONS: (state, data) => {
    state.regions = data;
  },
  SET_CITIES: (state, data) => {
    state.cities = data;
  },
  SET_POSITIONS: (state, data) => {
    state.positions = data;
  },
  SET_FORM_OPTIONS: (state, data) => {
    state.formOptions = data;
  },
  SET_BANKS: (state, data) => {
    state.banks = data;
  },
  SET_INN_LIST: (state, data) => {
    state.innList = data
  },
  SET_FILIALS: (state, data) => {
    state.filials = data;
  }
};