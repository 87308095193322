export default [
    {
      path: "/expert-opinion",
      name: "ExpertOpinion",
      meta: { middleware: "" },
      component: () => import("@/views/ExpertOpinion/ExpertOpinion.vue"),
    },
    {
      path: "/expert-opinion/:id",
      name: "ExpertOpinionDetail",
      meta: { middleware: "" },
      component: () => import("@/views/ExpertOpinion/expertOpinionDetail.vue"),
    },
  ];
  