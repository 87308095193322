// import { financialIndicatorsExistPeriods, getFinancialIndicators, storeFinancialIndicator, updateFinancialIndicator } from "@/api/financialIndicators";

import { method } from "@/api/api";

export const actions = {
    getFinancialIndicators({ commit }, params) {
        return method("crm.financial-indicators.index", params);
        // return new Promise((resolve, reject) => {
        // getFinancialIndicators(params)
        //     .then(res => {
        //         resolve(res);
        //     }).catch(error => {
        //         reject(error);
        //     });
        // });
    },
    storeFinancialIndicator({ commit }, data) {
        return new Promise((resolve, reject) => {
            storeFinancialIndicator(data)
                .then(res => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        });
    },
    updateFinancialIndicator({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateFinancialIndicator(data)
                .then(res => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        });
    },
    financialIndicatorsExistPeriods({ commit }) {
        return new Promise((resolve, reject) => {
            method("crm.financial-indicators.exist-periods")
                .then(res => {
                    commit('SET_FINANCIAL_INDICATORS_EXIST_PERIODS', res['data']);
                    resolve(res);
                }).catch(error => {
                    reject(error);
                });
        });
    }
};