import Vue from 'vue'

import DropdownDatepicker from '@/components/dropdown-datepicker/dropdown-datepicker';
Vue.component("dropdown-datepicker", DropdownDatepicker);

import PhoneMask from '@/components/elements/PhoneMask';
Vue.component('phone-input', PhoneMask);

import FormOption from '@/components/elements/FormOption';
Vue.component('form-option', FormOption);

import FormOption2 from '@/components/elements/FormOptionItParkFront';
Vue.component('form-option2', FormOption2);

import SalaryInput from '@/components/elements/SalaryInput';
Vue.component('SalaryInput', SalaryInput);

import Pagination from '@/components/elements/Pagination';
Vue.component('Pagination', Pagination);