// import {
//     createCode,
//     deleteCode,
//     getCodes, getInvoicesByCompany,
//     getMismatchedInvoices,
//     getRevenues,
//     getServicesCompany,
//     updateCode, updateInvoices
// } from "@/api/analytics";

import { method } from "@/api/api";

export const actions = {
    getIKPUCodes({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.ikpu-codes.index", params)
                .then((res) => {
                    commit("SET_LIST", res["data"]);
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getRevenues({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.ikpu-codes.get-excluded-services-revenue-by-region", params)
                .then((res) => {
                    commit("SET_REVENUE", res["data"]);
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    create({ commit }, data) {
        return method("crm.ikpu-codes.create", data)
    },

    update({ commit }, data) {
        return method("crm.ikpu-codes.edit", data)
    },
    delete({ commit }, data) {
        return method("crm.ikpu-codes.delete", data)
    },
    getServicesCompany({ commit }, data) {
        return method("crm.ikpu-codes.get-excluded-services-by-company", data)
    },
    getMismatchedInvoices({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.ikpu-codes.mismatched-invoice-statistics", params)
                .then((res) => {
                    commit("SET_MISMATCHED_INVOICES", res["data"]);
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getInvoicesByCompany({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.ikpu-codes.get-invoices-by-company", params)
                .then((res) => {
                    commit("SET_COMPANY_INVOICES", res["data"]);
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateInvoices({ commit }, data) {
        return method("crm.ikpu-codes.edit-invoice-verification", data)
    },
};
