const routes = [
  {
    path: "/debt-residents",
    name: "DebtResidents",
    meta: {
      middleware: "",
      nav: "DebtResidents",
    },
    component: () => import("@/views/DebtResidents/index.vue"),
  },
];

export default routes;
