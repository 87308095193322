<template>
  <span v-if="text">{{ text | t }}</span>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      default: () => null,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    propOptions() {
      return this.options;
    },
    text() {
      if (this.propOptions && this.propOptions.length && this.id) {
        return this.propOptions.find((item) => item.id == this.id);
      } else if (this.formOptions && this.formOptions.length > 0 && this.id) {
        return this.formOptions.find((item) => item.id == this.id);
      }
      return null;
    },
  },
};
</script>
