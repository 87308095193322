import { method } from "@/api/api";

export const actions = {
    getApplications({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.bp-app.index", params)
                .then(res => {
                    if (res['success'] && res['data']) {
                        commit('SET_APPLICATIONS', res['data']);
                    }
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    storeApplication({ commit }, data) {
        return new Promise((resolve, reject) => {
            storeApplication(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    showApplication({ commit }, id) {
        return new Promise((resolve, reject) => {
            showApplication(id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    sendReviewApplication({ commit }, id) {
        return new Promise((resolve, reject) => {
            sendReviewApplication(id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    showApplicationByUuid({ commit }, uuid) {
        return new Promise((resolve, reject) => {
            method("crm.bp-app.get-by-uuid", { uuid })
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    sendCommissionApplication({ commit }, data) {
        return new Promise((resolve, reject) => {
            method("crm.bp-app.send-commission", data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    rejectFromReviewApplication({ commit }, data) {
        return new Promise((resolve, reject) => {
            method("crm.bp-app.reject-from-review", data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    returnForIncomplateApplication({ commit }, data) {
        return new Promise((resolve, reject) => {
            method("crm.bp-app.return-for-incomplete", data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getCommissionApplications({ commit }, params) {
        return new Promise((resolve, reject) => {
            commissionApplications(params)
                .then(res => {
                    if (res['success'] && res['data']) {
                        commit('SET_APPLICATIONS', res['data']);
                    }
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getCommissionApplicationsRejected({ commit }) {
        return new Promise((resolve, reject) => {
            commissionApplicationsRejected()
                .then(res => {
                    if (res['success'] && res['data']) {
                        commit('SET_APPLICATIONS', res['data']);
                    }
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getCommissionApplicationsConfirmed({ commit }) {
        return new Promise((resolve, reject) => {
            commissionApplicationsConfirmed()
                .then(res => {
                    if (res['success'] && res['data']) {
                        commit('SET_APPLICATIONS', res['data']);
                    }
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    commissionShowApplicationByUuid({ commit }, uuid) {
        return new Promise((resolve, reject) => {
            commissionShowApplicationByUuid(uuid)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    confirmByCommission({ commit }, data) {
        return new Promise((resolve, reject) => {
            confirmByCommission(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    rejectByCommission({ commit }, data) {
        return new Promise((resolve, reject) => {
            rejectByCommission(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    confirm({ commit }, data) {
        return new Promise((resolve, reject) => {
            method("crm.bp-app.confirm", data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    rejectApplication({ commit }, data) {
        return new Promise((resolve, reject) => {
            method("crm.bp-app.reject", data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    cancel({ commit }, id) {
        return new Promise((resolve, reject) => {
            cancel(id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    updateApplication({ commit }, data) {
        return updateApplication(data);
    },
    getActiveBP({ commit }) {
        return new Promise((resolve, reject) => {
            getActiveBP()
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    setInActive({ commit }, data) {
        return new Promise((resolve, reject) => {
            setInActive(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getCertificateForAdmin({ commit }, uuid) {
        return new Promise((resolve, reject) => {
            getCertificateForAdmin(uuid)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getCertificate({ commit }) {
        return new Promise((resolve, reject) => {
            getCertificate()
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getContract({ commit }) {
        return new Promise((resolve, reject) => {
            method("crm.resource.generate-contract")
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getBpByUuid({ commit }, uuid) {
        return new Promise((resolve, reject) => {
            getBpByUuid(uuid)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getBpByApplicationUuid({ commit }, uuid) {
        console.log(uuid);
        return new Promise((resolve, reject) => {
            method("crm.bp-app.generate-bp-application-by-uuid", { uuid })
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    // Fore registration role
    getRegistrationApplications({ commit }, params) {
        return new Promise((resolve, reject) => {
            registrationApplications(params)
                .then(res => {
                    if (res['success'] && res['data']) {
                        commit('SET_APPLICATIONS', res['data']);
                    }
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    showRegistrationApplicationByUuid({ commit }, uuid) {
        return new Promise((resolve, reject) => {
            showRegistrationApplicationByUuid(uuid)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    confirmRegistration({ commit }, data) {
        return new Promise((resolve, reject) => {
            confirmRegistration(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    rejectRegistrationApplication({ commit }, data) {
        return new Promise((resolve, reject) => {
            rejectRegistrationApplication(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    sendRegistrationCommissionApplication({ commit }, data) {
        return new Promise((resolve, reject) => {
            sendRegistrationCommissionApplication(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    rejectRegistrationFromReviewApplication({ commit }, data) {
        return new Promise((resolve, reject) => {
            rejectRegistrationFromReviewApplication(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    returnRegistrationForIncomplateApplication({ commit }, data) {
        return new Promise((resolve, reject) => {
            returnRegistrationForIncomplateApplication(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getRegistrationCertificateForAdmin({ commit }, uuid) {
        return new Promise((resolve, reject) => {
            getRegistrationCertificateForAdmin(uuid)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    setRegistrationInActive({ commit }, uuid) {
        return new Promise((resolve, reject) => {
            setRegistrationInActive(uuid)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    // For monitoring role
    getMonitoringApplications({ commit }, params) {
        return new Promise((resolve, reject) => {
            getMonitoringApplications(params)
                .then(res => {
                    if (res['success'] && res['data']) {
                        commit('SET_APPLICATIONS', res['data']);
                    }
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    showMonitoringApplicationByUuid({ commit }, uuid) {
        return new Promise((resolve, reject) => {
            showMonitoringApplicationByUuid(uuid)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    // For support role
    getSupportApplications({ commit }, params) {
        return new Promise((resolve, reject) => {
            getSupportApplications(params)
                .then(res => {
                    if (res['success'] && res['data']) {
                        commit('SET_APPLICATIONS', res['data']);
                    }
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    showSupportApplicationByUuid({ commit }, uuid) {
        return new Promise((resolve, reject) => {
            showSupportApplicationByUuid(uuid)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    exportExcel({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.bp-app.excel-export", params)
                .then(res => {
                    resolve(res);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getBpByCompanyTin({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.bp-app.get-by-company", params)
                .then(res => {
                    resolve(res);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    searchResidentByName({ commit }, params) {
        return new Promise((resolve, reject) => {
            method('crm.bp-app.search-company-name', params)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    confirmedApplication({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("crm.bp-app.confirmed-applications", params)
                .then(res => {
                    console.log(res);
                    resolve(res);
                }).catch(error => {
                    reject(error);
                });
        });
    },
    companyApps({ commit }) {
        return new Promise((resolve, reject) => {
            companyApps()
                .then(res => {
                    commit('SET_CAN_ADD_BP', res['data']);
                    resolve(res);
                }).catch(error => {
                    reject(error);
                });
        });
    }
};