const routes = [
    {
        path: '/reports',
        name: 'Reports',
        meta: { middleware: 'Auth' },
        component: () =>
            import("@/views/reporting/QuarterlyReports.vue"),
    },
    {
        path: '/reports/request-quarterly-report',
        name: 'RequestQuarterlyReport',
        meta: { middleware: 'Auth' },
        component: () =>
            import("@/views/requestQuarterlyReport/Index.vue"),
    },
    {
        path: '/reports/audit-reports',
        name: 'AuditReports',
        meta: { middleware: 'Auth' },
        component: () =>
            import("@/views/reporting/AuditReports.vue"),
    },
    {
        path: '/reports/monthly-reports',
        name: 'MonthlyIncomes',
        meta: { middleware: 'Auth' },
        component: () =>
            import("@/views/monthlyIncomes/Index.vue"),
    },
    {
        path: "reports/monthly-reports/:uuid",
        name: "ShowMonthlyIncomes",
        meta: {
            middleware: "",
            nav: "Reports",
        },
        component: () => import("@/views/monthlyIncomes/Show.vue"),
    },
    {
        path: '/reports/request-monthly-report',
        name: 'RequestMonthlyReport',
        meta: { middleware: 'Auth' },
        component: () =>
            import("@/views/requestMonthlyReport/Index.vue"),
    },
    {
        path: '/reports/request-audit-report',
        name: 'RequestAuditReport',
        meta: { middleware: 'Auth' },
        component: () =>
            import("@/views/requestAuditReport/Index.vue"),
    },
    {
        path: "/reports/report-comparision",
        name: "ReportComparision",
        meta: {
            middleware: "",
            nav: "Reports",
        },
        component: () => import("@/views/reportComparision/Index.vue"),
    },
    {
        path: "/reports/report-comparision/:tin",
        name: "ShowReportComparision",
        meta: {
            middleware: "",
            nav: "Reports",
        },
        component: () => import("@/views/reportComparision/Show.vue"),
    },
]

export default routes;
